import React, { useEffect, useState } from "react";
import BlogImg1 from "../../assets/image/BlogImg/blogImg1.png";
import axios from "axios";

type latestType = {
  title: string;
  body: string;
  image: string;
  created_at: string;
};
const PostCard = () => {
  const [cards, setCards] = useState<latestType[] | []>([]);
  useEffect(() => {
    const fetchCards = async () => {
      const res = await axios.get(
        "https://realestate.api.arisaftech.co.jp/api/v2/post/latest"
      );
      console.log("inside cards ", res.data);
      setCards(res.data);
    };
    fetchCards();
  }, []);

  return (
    <>
      {cards.map((d) => (
        <div className="  d-flex justify-content-center align-items-center text-start  flex-row ">
          <div className="d-flex justify-content-center h-50 w-50">
            <img
              src={d.image}
              className="img-fluid d-flex justify-content-center"
              alt="..."
            />
          </div>
          <div className="card-body d-flex col  flex-column">
            <div>
              <h5 className="card-title fs-6  text-dark">{d.title}</h5>
            </div>
            <div className="views text-muted fs-6 ">
              <i className="fa-light  fa-calendar-range "> {d.created_at}</i>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PostCard;
