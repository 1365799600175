import React from "react";
import WhyUsImg1 from "../../assets/image/WhyUs/whyLogo1.png";
import WhyUsImg2 from "../../assets/image/WhyUs/whyLogo2.png";
import WhyUsImg3 from "../../assets/image/WhyUs/whyLogo3.png";

const WhyUs = () => {
  return (
    <>
      <div className="d-flex flex-column w-100 mt-5">
        <div className="home-margin">
        <div className="whyHeader whatlooking d-flex justify-content-center flex-column">
          <div className="d-flex justify-content-center text-center ">
            <h3 className="msg1">Why Choose Us</h3>
          </div>
          <div className="d-flex justify-content-center text-center msg2">
            <p className="msg2">We provice full service at every step</p>
          </div>
        </div>
       
           <div className="d-flex justify-content-center flex-row mb-5 margin-bottom ">
            <div className="row row-cols-1 row-cols-lg-3 row-cols-md-1 px-lg-5 g-4 mb-5 ">
              <div className="col">
                <div className="card  d-flex justify-content-center align-items-center box-shaddow">

                  <img src={WhyUsImg1} className="card-img-top img-size img-fluid" alt="..."/>
                  <div className="card-body d-flex justify-content-center text-center flex-column">
                      <h5 className="card-title text-dark">Why live in New York</h5>
                      
                      <p className="card-text text-muted ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem</p>
                      
                  </div>
                </div>
                
              </div>
              <div className="col">
                <div className="card  d-flex justify-content-center align-items-center box-shaddow">
                    <img src={WhyUsImg2} className="card-img-top img-size img-fluid" alt="..."/>
                    <div className="card-body d-flex justify-content-center text-center flex-column">
                        <h5 className="card-title text-dark">Why live in New York</h5>
                        
                        <p className="card-text text-muted ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem</p>
                        
                    </div>
                </div>
                
              </div>
              <div className="col">
                <div className="card  d-flex justify-content-center align-items-center box-shaddow">
                    <img src={WhyUsImg3} className="card-img-top img-size img-fluid" alt="..."/>
                    <div className="card-body d-flex justify-content-center text-center flex-column">
                        <h5 className="card-title text-dark">Why live in New York</h5>                       
                        <p className="card-text text-muted ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem</p>                       
                    </div>
                </div>                
              </div>             
            </div>
          </div>
          </div>
      </div>
    </>
  );
};

export default WhyUs;
