import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Redirect from "./dummy/Redirect";
import Home from "./Pages/Home/home";
import Blog from "./Pages/Blog/blogs";
import Service from "./Pages/Service/service";
import About from "./Pages/About/about";
import Contact from "./Pages/Contact/contact";
import FilterBuy from "./Components/Filter/filterBuy";
import FilterSell from "./Components/Filter/filterSell";
import FilterRent from "./Components/Filter/filterRent";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthContextProvider } from "./Components/context/AuthContext";
import ResetPassword from "./Components/ForgotPassword/resetPass";
import AddProperty from "./Components/AddProperty/addPropertyS1";
import AddProperty2 from "./Components/AddProperty/addPropertyS2";
import Policy from "./Components/PrivacyPolicy/policy";
// import PropertyList from "./Components/latest/propertiesList";
import PropertyList from "./Components/Latest/propertiesList";
import MyAccount from "./Pages/My Account/myAccount";
// import PropertyDetails from "./Components/latest/propertyDetails";
import PropertyDetails from "./Components/Latest/propertyDetails";
import SingleBlog from "./Pages/Blog/singleBlog";
import SellerDetails from "./Components/AddProperty/sellerDetails";
import MyAccountEdit from "./Pages/My Account/myAccountEdit";
import Test from "./Pages/Test/test";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    ),
  },
  {
    path: "/login",
    element: <Redirect />,
  },
  {
    path: "/login/my-account",
    element: (
      <AuthContextProvider>
        <MyAccount />
      </AuthContextProvider>
    ),
  },
  {
    path: "/login/my-account/edit-info",
    element: <MyAccountEdit />,
  },
  {
    path: "/login/add-property",
    element: <AddProperty />,
  },
  {
    path: "/login/add-property2",
    element: <AddProperty2 />,
  },
  {
    path: "/home/login/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/home",
    element: (
      // <AuthContextProvider>
      <Home />
      // </AuthContextProvider>
    ),
  },
  {
    path: "/home/filter-buy",
    element: <FilterBuy />,
  },
  {
    path: "/home/filter-sell",
    element: <FilterSell />,
  },
  {
    path: "/home/filter-rent",
    element: <FilterRent />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/service",
    element: (
      // <AuthContextProvider>
      <Service />
      // </AuthContextProvider>
    ),
  },
  {
    path: "/blogs",
    element: <Blog />,
  },
  {
    path: "/blogs/blog-details",
    element: <SingleBlog />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/home/property-list",
    element: <PropertyList />,
  },
  {
    path: "/home/property-details",
    element: <PropertyDetails />,
  },

  {
    path: "/home/property-details/seller-profile",
    element: <SellerDetails />,
  },

  {
    path: "/footer/policy",
    element: <Policy />,
  },
  {
    path: "/test",
    element: (
      <AuthContextProvider>
        <Test />
      </AuthContextProvider>
    ),
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}></RouterProvider>
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
