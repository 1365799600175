import React, { useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import "./nav.css";
import Logo from "../../assets/image/Home/logo.jpg";
import Search from "../Search/search";
import Login from "../Login/login";
import HomeContent from "../HomeContent/homeContent";
import { useContext, useState } from "react";
import SignUp from "../SignUp/signup";
import { AuthContext, AuthContextProvider } from "../context/AuthContext";
import ResetPassword from "../ForgotPassword/resetPass";
import ResetOTP from "../ForgotPassword/resetOTP";
import ChangePassword from "../ForgotPassword/updatePass";
// import Congratulations from "../ForgotPassword/Congratulations";
import Congratulations from "../ForgotPassword/congratulations";
import userImg from "../../assets/image/Others/userImg.png";

const NavBar = () => {
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [signUp, setSignUp] = useState<boolean>(false);
  const [forget, setForget] = useState<boolean>(false);
  const [otp, setOtp] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [congrates, setCongrates] = useState<boolean>(false);
  const [beforLoginNav, setBeforeLoginNav] = useState<boolean>(true);
  const [afterLoginNav, setAfterLoginNav] = useState<boolean>(false);
  const [logout, setLogout] = useState<Boolean>(false);

  const navLinkStyles = ({isActive}:any) => {
    return {
      color: isActive? '#1F53B9': 'rgba(0, 0, 0, 1)',
    }

  }

  const items = JSON.parse(localStorage.getItem("user") || "{}");
  const { user } = useContext(AuthContext);
  const toggleLogin = () => {
    setLoginModal(true);
  };
  const handleLogout = () => {
    setAfterLoginNav(false);
    console.log("inside navbar logout");
    setBeforeLoginNav(true);
    console.log("logout items ", items);
    localStorage.removeItem("user");
  };

  const toggleRegister = () => {
    // setRegisterModal(true);
  };

  const overlaySignInClose = () => {
    setSignUp(true);
    setLoginModal(false);
  };

  const overlaySignOutClose = () => {
    setLoginModal(true);
    setSignUp(false);
  };

  useEffect(() => {}, [items]);
  useEffect(() => {}, [user]);
  return (
    <>
      <div className="d-flex flex-column fixed-top ">
        <div className="child1 d-flex flex-column">
          <nav className="navbar navbar-expand-lg navbar-light bg-white  ">
            <div className="container px-0 ">
              <div className="logo">
                <Link to="/">
                  <img className="imglogo navbar-brand" src={Logo} alt="logo" />
                </Link>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse "
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0 d-flex" style={{paddingRight:"120px"}}>
                  <li className="nav-item ">
                    <NavLink to={"/"} className="nav-link menu-home" style={navLinkStyles}  >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink to={"/about"} className="nav-link menu-home" style={navLinkStyles}>
                      About
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink to={"/service"} className="nav-link menu-home" style={navLinkStyles} >
                      Service
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink to={"/blogs"} className="nav-link menu-home" style={navLinkStyles} >
                      Blog
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={"/contact"} className="nav-link menu-home" style={navLinkStyles}>
                      Contact
                    </NavLink>
                  </li>
                </ul>  
                {/* {beforLoginNav && ( */}
                {!items?.username && (       
                  <div className="d-flex flex-row align-items-center ">
                    <div className="d-none d-lg-block lg-screen-search ">
                     <Search />
                    </div>
                    <div className=" ">
                      <button
                        onClick={toggleLogin}
                        type="button"
                        className="login menu-home  text-center btn-lg d-none d-lg-block"
                      >
                        Sign in{" "}
                      </button>
                    </div>
                    <div className="">
                      <button
                        onClick={() => {
                          setSignUp(true);
                        }}
                        type="button"
                        className="  btn-lg d-none d-lg-block signup-btn "
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                )}
                {/* {afterLoginNav && ( */}
                {items?.username && (
                  <div className="d-flex flex-row justify-content-center align-items-center  ">
                    <div className="d-flex justify-content-end align-items-end">
                      <img
                        className="d-flex justify-content-end align-items-center imglogo navbar-brand"
                        src={userImg}
                        alt="logo"
                        style={{
                          height: "40px",
                          width: "30px",
                          borderRadius: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>

                    <div className="btn-group mt-4">
                      <div>
                        <h6 className=" ">{items?.username}</h6>
                      </div>
                      <p
                        className="  dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="visually-hidden">Toggle Dropdown</span>
                      </p>

                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            to="/login/my-account"
                            className="dropdown-item"
                          >
                            My Account
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/login/add-property"
                            className="dropdown-item"
                          >
                            Add property
                          </Link>
                        </li>
                        <li>
                          <Link to="/" className="dropdown-item">
                            My Favorite
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/"
                            className="dropdown-item"
                            onClick={handleLogout}
                          >
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>

      {loginModal && (
        <Login
          setLoginModal={setLoginModal}
          overlaySignInClose={overlaySignInClose}
          setForget={setForget}
          setBeforeLoginNav={setBeforeLoginNav}
          setAfterLoginNav={setAfterLoginNav}
        />
      )}

      {signUp && (
        <SignUp
          setSignUp={setSignUp}
          overlaySignOutClose={overlaySignOutClose}
        />
      )}
      {forget && <ResetPassword setForget={setForget} setOtp={setOtp} />}
      {otp && (
        <ResetOTP setOtp={setOtp} setChangePassword={setChangePassword} />
      )}
      {changePassword && (
        <ChangePassword
          setChangePassword={setChangePassword}
          setCongrates={setCongrates}
        />
      )}
      {congrates && <Congratulations setCongrates={setCongrates} />}
    </>
  );
};

export default NavBar;
