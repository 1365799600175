import NavBar from "../NavBar/nav";
import Footer from "../Footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import locationIcon from "../../assets/image/map-marker-Light.png";
import PropertyImg1 from "../../assets/image/PropertyImg/propertyImg1.png";
import PropertyImg2 from "../../assets/image/PropertyImg/propertyImg2.png";
import PropertyImg3 from "../../assets/image/PropertyImg/propertyImg3.png";
import PropertyImg4 from "../../assets/image/PropertyImg/propertyImg4.png";
import userImg from "../../assets/image/Others/userImg.png";
import ReviewIcon from "../../assets/image/PropertyImg/Rivew Icon.png";
import bed from "../../assets/image/bed.png";
import bath from "../../assets/image/bath.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import RatingSystem from "./Rating";
import BookAppointment from "../BookAppointment/bookAppointment";
import PropertyComment from "./propertyComment";
import AddReview from "./review";

const PropertyDetails = () => {
  return (
    <>
      <div className=" d-flex flex-column bg mt-5 ">
        <div className=" fixed-top ">
          <NavBar />
        </div>
        <div className="d-flex justify-content-center flex-column home-margin padding gap-4">
          <div className="d-flex flex-row justify-content-center gap-4">
            <div className="d-flex flex-column">
              <div className="d-flex flex-column ">
                <img
                  src={PropertyImg1}
                  className=" img-fluid object-fit-cover"
                  alt="..."
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-4">
              <div className="d-flex flex-column ">
                <img
                  src={PropertyImg2}
                  className=" img-fluid object-fit-cover "
                  alt="..."
                />
              </div>
              <div className="d-flex flex-column ">
                <img
                  src={PropertyImg3}
                  className=" img-fluid object-fit-cover "
                  alt="..."
                />
              </div>
              <div className="d-flex flex-column ">
                <img
                  src={PropertyImg4}
                  className=" img-fluid object-fit-cover "
                  alt="..."
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row gap-5 ">
            <div className="d-flex flex-column gap-5 mt-3 screen-size-60  ">
              <div className="d-flex flex-column ">
                <div>
                  <h6 className="card-title text-dark fs-2 fw-bold">
                    Villa On Washington Ave
                  </h6>
                </div>
                <div className="d-flex flex-row  fw-normal text-muted fs-6 mb-1 gap-5">
                  <div className="d-flex gap-2 text-start">
                    <div className="d-flex justify-content-center text-dark gap-2">
                      <i
                        className="fa-solid fa-location-dot pt-2"
                        style={{ color: "#1F53B9" }}
                      ></i>
                      333-399 8th Ave, New York, USA
                    </div>
                  </div>
                  <div className="d-flex justify-content-end ">
                    <button className="buttton1">View Map</button>
                  </div>
                </div>
                <div className="d-flex gap-3 ">
                  <RatingSystem />
                  <h6 className="d-flex pt-1" style={{ color: "#F7941D" }}>
                    (4.8)
                  </h6>
                </div>
              </div>
              <div className="d-flex flex-column line">
                <div className="card-title text-dark  h3">Description</div>
                <div className="d-flex justify-content-center mb-3">
                  Welcome to your dream home! This luxurious property is the
                  epitome of elegance and sophistication. Boasting a spacious
                  layout, it is perfect for hosting lavish gatherings and
                  entertaining guests. The gourmet kitchen features
                  top-of-the-line appliances, sleek cabinetry, and ample counter
                  space, making it a chef's paradise. The open concept design
                  seamlessly flows into the dining area, making it an ideal spot
                  for intimate dinner parties or large family gatherings. The
                  comfortable bedrooms exude a sense of
                </div>
              </div>
              <div className="d-flex flex-column justify-content-start gap-2">
                <div className="d-flex text-dark h3">Facility</div>
                <div className="d-flex justify-content-start ">
                  <div className="d-flex h6 text-muted justify-content-start">
                    1700 ft2
                  </div>
                  <div className="d-flex h6 text-muted justify-content-start">
                    5 Bedrooms
                  </div>
                  <div className="d-flex h6 text-muted justify-content-start">
                    1 Kitchen
                  </div>
                  <div className="d-flex h6 text-muted justify-content-start">
                    3 bathroom
                  </div>
                  <div className="d-flex h6 text-muted justify-content-start">
                    Pool
                  </div>
                </div>

                <div className="d-flex w-75 justify-content-start">
                  <div className="d-flex h6 text-muted justify-content-start">
                    Garage
                  </div>
                  <div className="d-flex h6 text-muted justify-content-start">
                    Garden
                  </div>
                  <div className="d-flex h6 text-muted justify-content-start">
                    CCTV
                  </div>
                  <div className="d-flex h6 text-muted justify-content-start">
                    Belcony
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column gap-5 mt-3 screen-size-40 ">
              <div className="d-flex card w-100 h-50 gap-2 ">
                <div className=" d-flex justify-content-between  line">
                  <div className="d-flex fs-4 text-dark fw-bold  mb-2">
                    Price:
                  </div>
                  <div className="d-flex fs-4 fw-bold">$3,00,000</div>
                </div>
                <div className="d-flex justify-content-between ">
                  <div className=" fs-5 text-dark mt-2">
                    <i
                      className="fa-regular fa-heart"
                      style={{ color: "#FF0000" }}
                    ></i>{" "}
                    Add to Favorite List
                  </div>
                  <div className="d-flex justify-content-between mt-3 gap-3">
                    <i
                      className="fa-solid fa-share-nodes"
                      style={{ color: "#000000" }}
                    ></i>
                    <i
                      className="fa-solid fa-print"
                      style={{ color: "#000000" }}
                    ></i>
                  </div>
                </div>
              </div>

              <div className="d-flex card w-100 h-50 gap-2 ">
                <div className=" d-flex flex-column justify-content-between  ">
                  <div className="d-flex fs-4 text-dark fw-bold  mb-1">
                    Contact
                  </div>
                  <div className="d-flex fs-4 fw-bold">
                    <div className="d-flex flex-column ">
                      <div className="d-flex justify-content-between gap-4 mb-3 line">
                        <div className="d-flex justify-content-center mb-2">
                          <img
                            className="d-flex justify-content-end align-items-center imglogo navbar-brand"
                            src={userImg}
                            alt="logo"
                            style={{
                              height: "70px",
                              width: "70px",
                              borderRadius: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="d-flex">
                          <div className="d-flex flex-column ">
                            <h6 className="text-muted">seller</h6>
                            <div className="fs-5 text-dark">
                              Darlene Robertson
                            </div>
                          </div>
                          <div>
                            <Link to={"/home/property-details/seller-profile"}>
                              <h6>See Profile</h6>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <div className="d-flex justify-content-between line">
                          <h6 className="text-dark">Contact No.</h6>
                          <h6 className="text-muted">(603) 555-0123</h6>
                        </div>
                        <div className="d-flex justify-content-between line">
                          <h6 className="text-dark">Email</h6>
                          <h6 className="text-muted">wikinerd@verizon.net</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <BookAppointment />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column gap-5 mt-3 screen-size-60 ">
            <PropertyComment />
            <PropertyComment />
            <AddReview />
          </div>
        </div>

        <div className="d-flex flex-column">
          <Footer />
        </div>
      </div>
      <div></div>
    </>
  );
};
export default PropertyDetails;
