import React, { useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// const ResetPassword = ({setloginModal}:any) => {
const ResetOTP = ({setOtp, setChangePassword}: any) => {

    const handleSubmit = async (e: any) => {
        e.preventDefault();
       
      };

    // const [resetModal, setResetPasswordModal] = useState<boolean>(false);
    // const handlePopUpSubmit = () => {
    //   setloginModal(false);
    // }
    const handleChangePass = () => {
        setOtp(false);
        setChangePassword(true);
       }
    
  return (
    <>
      
      <div className="Auth-form-container" onClick={() => setOtp(false)}>
      <form className="Auth-form d-flex justify-content-center align-items-center bg"  onClick={(e)=>{e.stopPropagation()}}>
        <div className="Auth-form-content ">
          
          <div className="d-flex justify-content-center flex-column text-center">
            <h3 className="Auth-form-title">Enter 4-digit OTP code</h3>
            <p className="text-muted">We have sent you 4 digit code via your email, Please check your email.</p>
          </div>
         
          
          <div className="d-flex  flex-column otp-box-size justify-content-center form-input mt-4 mb-4 ">
            <div className="list-unstyled d-flex  flex-row justify-content-center fill-flex  gap-3 " style={{fontSize: "40px"}}>
                <input className="textarea bg-white text-center d-flex w-25 h-50 p-3 box-shaddow "type="text" maxLength={1}/>
                <input className="textarea bg-white text-center d-flex w-25 h-50 p-3 box-shaddow"type="text" maxLength={1}/>
                <input className="textarea bg-white text-center d-flex w-25 h-50 p-3 box-shaddow"type="text"maxLength={1}/>
                <input className="textarea bg-white text-center d-flex w-25 h-50 p-3 box-shaddow"type="text"maxLength={1}/>      
              </div>
            </div>
          <div className="d-grid gap-2 mt-4 mb-4">
            <button type="submit" className="btn btn-primary p-2 " onClick={handleChangePass}>
              Verify OTP
            </button>
          </div>
            <p className="text-center text-muted">00:60</p>
          <div>
          <p className=" text-center text-muted ">
          Don’t receive the code? &nbsp; 
            <a className="registration fw-bold">
               Resend OTP
            </a>
          </p>
          </div>    
        </div>
      </form>
    </div>
    
    </>
  );
};
export default ResetOTP;
