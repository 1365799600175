import React, { useState, useEffect } from "react";
import "./blog.css";
import NavBar from "../../Components/NavBar/nav";
import BlogCard from "./blogCard";
import CategoryCard from "./categoryCard";
import PostCard from "./postCard";
import Footer from "../../Components/Footer/footer";
import axios from "axios";
import BlogCardDetails from "./blogCardDetails";
import BlogList from "./blogList";

const SingleBlog = () => {
  return (
    <div>
      <div className="fixed-top">
        <NavBar />
      </div>
      <div className="d-flex bg  flex-column justify-content-center align-items-center ">
        <div className="mt-5 d-flex flex-column justify-content-center blog-content  ">
          {/* <img className="img-fluid " src={ContactCoverImg} alt="" /> */}
          <div>
            {" "}
            <h1 className="fw-bold">Our Real Estate Blog</h1>
          </div>
          <div>
            {" "}
            <p className="fs-5">
              Read the latest news from the real estate industry as it happens
            </p>
          </div>
        </div>
        <div className=" d-flex justify-content-between flex-row  text-center p-5 home-margin row row-cols-1 row-cols-lg-3 row-cols-md-1 g-3">
          <div className="d-flex justify-content-start flex-column  flex-grow-1">
            <BlogCardDetails />
          </div>
          <div className="d-flex justify-content-start flex-column flex-shrink-1  gap-3">
            <div className="category d-flex justify-content-start  flex-column">
              <CategoryCard />
            </div>
            <div className="latest-post d-flex flex-column ">
              <div className="card h-100 d-flex text-start  flex-column box-shaddow">
                <div className="fs-4 fw-bold">Latest post</div>
                <div className="row ">
                  {" "}
                  <PostCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default SingleBlog;
