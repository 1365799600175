import React, { useRef, useContext } from "react";
import { loginCall } from "../../apiCalls";
import { AuthContext } from "../../Components/context/AuthContext";
const Test = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const { user, dispatch } = useContext(AuthContext);
  const handleClick = async (e: any) => {
    e.preventDefault();
    console.log(emailRef.current?.value);
    console.log(passwordRef.current?.value);
    loginCall(
      {
        email: emailRef.current?.value,
        password: passwordRef.current?.value,
      },
      dispatch
    );
  };
  console.log("test ", user);
  return (
    <div>
      <form>
        <input type="email" ref={emailRef} />
        <input type="text" ref={passwordRef} />
        <button type="submit" onClick={handleClick}>
          submit
        </button>
      </form>
    </div>
  );
};

export default Test;
