
import 'react-datepicker/dist/react-datepicker.css';

import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import EditIcon from "../../assets/image/Others/editIcon.svg"
import { Link } from "react-router-dom";

const VisibleInfo: React.FC  = () => {
  return (
      


        
            <div className="d-flex flex-row mt-5 mb-5 gap-5 flex-grow-1 fill-flex  " >
                <div className="d-flex flex-column info w-100 gap-2 ">
                    
                        <div className="d-flex flex-row justify-content-between gap-5 w-100" >
                            <div className="d-flex flex-column fw-bold fs-5 mt-2 mb-2"> My Account Information</div>
                            <div className="d-flex align-items-center "><Link to={"/login/my-account/edit-info"}><img  src={EditIcon} alt="" /></Link></div>
                        </div>
                        <div className="d-flex justify-content-evenly bg-white rounded-1 w-100 p-2">
                            <div className="w-100">Name</div>  
                            <div className="w-25 d-flex ">:</div>
                            <div className="w-100">Md Solaiman Ovi </div>
                        </div>
                        <div className="d-flex justify-content-between bg-white rounded-1 w-100 p-2">
                            <div className="w-100"> Date of Birth</div>  
                            <div className="w-25 d-flex ">:</div> 
                            <div className="w-100 ">01.01.1995</div>
                        </div>
                        <div className="d-flex justify-content-between bg-white rounded-1 w-100 p-2">
                            <div className="w-100"> Gender</div>  
                            <div className="w-25 d-flex ">:</div> 
                            <div className="w-100">Male</div>
                        </div>
                        <div className="d-flex justify-content-between bg-white rounded-1 w-100 p-2">
                            <div className="w-100"> contact number</div>  
                            <div className="w-25 d-flex ">:</div> 
                            <div className="w-100">0173******4</div>    
                        </div>
                        <div className="d-flex justify-content-between bg-white rounded-1 w-100 p-2">
                            <div className="w-100">Email Address</div>  
                            <div className="w-25 d-flex ">:</div> 
                            <div className="w-100">msovi480@gmail.com</div> 
                        </div>
                        <div className="d-flex justify-content-between bg-white rounded-1 w-100 p-2">
                            <div className="w-100"> NID No </div>  
                            <div className="w-25 d-flex ">:</div> 
                            <div className="w-100">123456789</div> 
                        </div>
                        <div className="d-flex justify-content-between bg-white rounded-1 w-100 p-2">
                            <div className="w-100"> Passport No </div>  
                            <div className="w-25 d-flex ">:</div> 
                            <div className="w-100">102030405060</div>
                        </div>
                        <div className="d-flex bg-white rounded-1 w-100 p-2">
                            <div className='mb-5'>
                                Description
                            </div>
                        </div>

                    
                    
                </div>
                
                    <div className="d-flex flex-column address gap-2 w-100 ">
                        <div className="d-flex justify-content-between gap-5 w-100" >
                            <div className="d-flex flex-column fw-bold fs-5 mt-2 mb-2"> My Account Information</div>
                            <div className="d-flex align-items-center "><Link to={"/login/my-account/edit-info"}><img  src={EditIcon} alt="" /></Link></div>
                        </div>
                        <div className="d-flex bg-white rounded-1 w-100 p-2">
                            <div className="w-100"> Division </div>  
                            <div className="w-25 d-flex ">:</div> 
                            <div className="w-100">Dhaka</div>
                        </div>
                        <div className="d-flex bg-white rounded-1 w-100 p-2">
                            <div className="w-100"> District </div>  
                            <div className="w-25 d-flex">:</div> 
                            <div className="w-100">Dhaka</div>
                        </div>
                        <div className="d-flex bg-white rounded-1 w-100 p-2">
                            <div className="w-100">Sub District </div>  
                            <div className="w-25 d-flex">:</div> 
                            <div className="w-100">Dhaka</div>
                        </div>
                        <div className="d-flex bg-white rounded-1 w-100 p-2">
                            <div className="w-100">Area </div>  
                            <div className="w-25 d-flex">:</div> 
                            <div className="w-100">Dhaka</div>
                        </div>
                        <div className="d-flex bg-white rounded-1 w-100 p-2">
                            
                            <div className='mb-5'>
                                Address
                            </div>
                        </div>

                    </div>
                
            </div>
       
  
        


        // <div className="d-flex flex-column gap-2">
        //      <div className="d-flex w-100 gap-5 bg-white p-2 justify-content-between">
        //         <div className="d-flex w-50 justify-content-between">
        //             <div>Name </div>
        //             <div className="d-flex align-items-center"><div>:</div></div>
        //         </div>
        //         <div className="w-50">Md Solaiman Ovi</div>
        //     </div>
             
        //     <div className="d-flex w-100 gap-5 bg-white p-2 justify-content-between">
        //         <div className="d-flex w-50 justify-content-between">
        //             <div>Date of Birth</div>
        //             <div>:</div>
        //         </div>
        //         <div className="w-50">NAME@GMAIL.COM</div>
        //     </div>

        //     <div className="d-flex w-100 gap-5 bg-white p-2 justify-content-between">
        //         <div className="d-flex w-50 justify-content-between">
        //             <div>Name</div>
        //             <div>:</div>
        //         </div>
        //         <div className="w-50">a@gmail.com</div>
        //     </div>
                  
        // <div className="container">
        //         <div className="row">
        //         <div className="col-4">
        //             <div>Nameddddd</div>
        //         </div>
        //         <div className="col-1">:</div>
        //         <div className="col-7">NAME@GMAIL.COMdddddddddddddddddd</div>
        //         </div>
        //         <div className="row">
        //         <div className="col-4">
        //             <div>Nameddddd</div>
        //         </div>
        //         <div className="col-1">:</div>
        //         <div className="col-7">NAME@GMAIL.COMdddddddddddddddddd</div>
        //         </div>
        //         <div className="row">
        //         <div className="col-4">
        //             <div>Nameddddd</div>
        //         </div>
        //         <div className="col-1">:</div>
        //         <div className="col-7">NAME@GMAIL.COMdddddddddddddddddd</div>
        //         </div>
        //         <div className="row">
        //         <div className="col-4">
        //             <div>Nameddddd</div>
        //         </div>
        //         <div className="col-1">:</div>
        //         <div className="col-7">NAME@GMAIL.COMdddddddddddddddddd</div>
        //         </div>
        //         <div className="row">
        //         <div className="col-4">
        //             <div className="text-break">Nameddddddddddddddddddddddddddddddd</div>
        //         </div>
        //         <div className="col-1">:</div>
        //         <div className="col-7">NAME@GMAIL.COMddddddddddddddddddfffffffffffffffffffffffffffffffffff</div>
        //         </div>
        //         <div className="row">
        //         <div className="col-4">
        //             <div>Nameddddd</div>
        //         </div>
        //         <div className="col-1">:</div>
        //         <div className="col-7">NAME@GMAIL.COMdddddddddddddddddd</div>
        //         </div>
        //     </div>
     
        // </div>
        

  );
};
export default VisibleInfo;
