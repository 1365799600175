import React, {
  useContext,
  useRef,
  useState,
  useReducer,
  useEffect,
} from "react";
import "./login.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { loginCall } from "../../apiCalls";
import { AuthContext } from "../context/AuthContext";
import Logo from "../../assets/image/Home/logo.jpg";
import Gmail from "../../assets/image/Others/Gmail.png";
import Facebook from "../../assets/image/Others/Facebook.png";
import ResetPassword from "../ForgotPassword/resetPass";
import SignUp from "../SignUp/signup";

const Login = ({
  setLoginModal,
  overlaySignInClose,
  setForget,
  setOtp,
  setBeforeLoginNav,
  setAfterLoginNav,
}: any) => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [resetModal, setResetModal] = useState<boolean>(false);
  const { user, isFetching, error, dispatch } = useContext(AuthContext);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log("handle login");
    loginCall(
      {
        email: emailRef.current?.value,
        password: passwordRef.current?.value,
      },
      dispatch
    );
    // window.location.reload();
  };
  const handleForget = () => {
    // console.log("worked");
    setLoginModal(false);
    setForget(true);
  };
  const handleLogin = async (e: any) => {
    e.preventDefault();
    setBeforeLoginNav(false);
    setLoginModal(false);
    console.log("Inside login");
    loginCall(
      {
        email: emailRef.current?.value,
        password: passwordRef.current?.value,
      },
      dispatch
    );

    if (user) {
      setAfterLoginNav(true);
    } else {
      setAfterLoginNav(true);
    }
  };

  console.log("cont ", user);
  return (
    <>
      <div
        className="Auth-form-container"
        onClick={() => {
          setLoginModal(false);
        }}
      >
        <form
          className="Auth-form d-flex justify-content-center align-items-center flex-column"
          onSubmit={handleSubmit}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="Auth-form-content d-flex justify-content-center flex-column  ">
            <div className=" d-flex justify-content-center">
              <img className=" logo1" src={Logo} alt="logo" />
            </div>
            <div className="d-flex justify-content-center flex-column text-center">
              <h3 className="Auth-form-title">Sign In</h3>
              <p className="text-muted">Hey, good to see you again</p>
            </div>

            <div className="form-input mt-3">
              <i className="fa-regular fa-envelope text-muted"></i>
              <input
                type="text"
                className="username form-control   "
                placeholder="Enter Your Email"
                ref={emailRef}
              />
            </div>

            <div className="form-input mt-2 ">
              <i className="fa fa-lock text-muted"></i>
              <input
                type="password"
                className="password form-control mt-1"
                placeholder="Password"
                ref={passwordRef}
                minLength={6}
              />
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name=""
                    id=""
                    value="checkedValue"
                  />
                  Remember me
                </label>
              </div>
              <div>
                <p
                  className=" forget-psw text-start text-decoration-underline"
                  onClick={handleForget}
                  style={{ cursor: "pointer" }}
                >
                  {/* <a className="forget-psw text-decoration-underline" href="">
                    Forgot Password
                  </a> */}
                  Forgot password
                </p>
              </div>
            </div>
            <div className="d-grid gap-2 mt-1">
              <button className="btn btn-primary p-2 " onClick={handleLogin}>
                {/* <button className="btn btn-primary p-2 " type="submit"> */}
                Sign In
              </button>
            </div>
            <div className="d-flex justift-content-center align-items-center flex-column mt-3">
              <div>Or Sign in with</div>
              <div className="">
                <img className=" " src={Gmail} alt="gmail" />
                <img className=" " src={Facebook} alt="facebook" />
              </div>
              <div>
                <p className=" text-center text-muted ">
                  Don't have any account? &nbsp;
                  <a
                    className="registration fw-bold"
                    onClick={overlaySignInClose}
                    href="#"
                  >
                    Sign Up
                  </a>
                </p>
                {/* <p className=" text-center text-muted mt-2">
                  Don't have any account?
                  <p className="registration fw-bold " onClick={overlaySignInClose} >
                    Sign Up
                  </p>
                </p> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default Login;
