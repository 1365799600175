import "./footer.css";
import Fb_icon from "../../assets/image/Footer/Facebook.svg";
import Twiter_icon from "../../assets/image/Footer/twitter.svg";
import Insta_icon from "../../assets/image/Footer/Shape.svg";
import Linkedin_icon from "../../assets/image/Footer/Linkdin.svg";
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    
      <footer className="text-start text-sm-start text-lg-start text-white d-flex justify-content-center flex-column bg-color">
        <div className="container d-flex justify-content-center align-items-center footer-padding">
            <div className="row d-flex justify-content-between">
              <div className="col-md-3 col-lg-3 col-xl-3  mt-3 d-flex flex-column">
                <div className="footer-title mb-4">
                  About Us
                </div>
                <div className="title-info ">
                  Our Real Estate Inc company is committed to delivering a high
                  level of expertise, customer service, and attention to detail
                  to the marketing and sales of luxury real estate, and rental
                  properties.
                </div>
              </div>
              <hr className="w-100 clearfix d-md-none" />
              <div className="col-md-2 col-lg-2 col-xl-2  mt-3">
                <div className="mb-4 footer-title">
                  Company info
                </div>
                <div className="d-flex flex-column gap-2">
                  <Link to={"/about"}> <div className="title-info">
                    About Us
                  </div></Link>
                  <Link to="/footer/policy">
                    <div className="title-info">
                      Terms & Conditions
                    </div>
                  </Link>
                  <div className="title-info">
                    Support Center
                  </div>
                  <div className="title-info">
                    Press Info
                  </div>
                </div>
               
              </div>

              <hr className="w-100 clearfix d-md-none" />
              <div className="col-md-3 col-lg-2 col-xl-2 mt-3 d-flex flex-column justify-content-start contact-us-width"style={{width:"20%"}} >
                <div className="footer-title mb-4">
                  Contact Us
                </div>
                <div className="d-flex flex-column gap-2">
                  <div className="title-info">
                    <i className="fas fa-home  text-muted"></i> New York,NY 10012, US
                  </div>
                  <div className="title-info">
                    <i className="fas fa-envelope text-muted"></i>{" "}
                    support@yourmail.net
                  </div>
                  <div className="title-info">
                    <i className="fas fa-phone text-muted"></i> (123)456-7890
                  </div>
                  <div className="title-info">
                    <i className="fas fa-print mr-3 text-muted"></i>{" "}
                    www.yourwebsite.com
                  </div>
                </div>
              </div>

              <hr className="w-100 clearfix d-md-none  " />
              <div className="col-md-4 col-lg-3 col-xl-3 mt-3 d-flex flex-column follow-us-width" style={{width:"12%"}}>
                <div className="footer-title mb-4">
                  Follow Us
                </div>
                <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end d-flex flex-row justify-content-start gap-2">
                
                <img src={Twiter_icon} alt="" />
                <img src={Fb_icon} alt="" />
                <img src={Linkedin_icon} alt="" />
                <img src={Insta_icon} alt="" />
                </div>
              </div>
            </div>
        </div>
        <div className="top-line pt-0 row d-flex justify-content-center align-items-center mt-4">
              <div className="col-md-7 col-lg-8 text-center text-md-start d-flex justify-content-center">   
                  <a className=" p-2 rs-rights" href="#">© Real Estate. All Rights Reserved</a> 
              </div>
        </div>
      </footer>
    
  );
};
export default Footer;
