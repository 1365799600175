import { useState, useEffect, useContext } from "react";
import "./service.css";
import NavBar from "../../Components/NavBar/nav";
import Footer from "../../Components/Footer/footer";
import ServiceImg1 from "../../assets/image/Service/serviceImg1.png";
import ServiceCard from "./serviceCard";
import axios from "axios";
import { AuthContext } from "../../Components/context/AuthContext";

type serviceType = {
  id: string;
  author: string;
  title: string;
  description: string;
  image: string | undefined;
};

const Service = () => {
  const [services, setServices] = useState<serviceType[] | []>([]);
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const fetchServices = async () => {
      const res = await axios.get(
        "https://realestate.api.arisaftech.co.jp/api/v2/service/"
      );
      // console.log("Inside service", res.data);
      setServices(res.data);
    };
    fetchServices();
  }, [services]);
  useEffect(() => {
    console.log("service ", user);
  }, [user]);

  return (
    <div>
      <div className="fixed-top">
        <NavBar />
      </div>
      <div className="d-flex  flex-column justify-content-center align-items-center ">
        <div className="mt-5 d-flex flex-column justify-content-center service-content  ">
          {/* <img className="img-fluid " src={ContactCoverImg} alt="" /> */}
          <div>
            {" "}
            <h1 className="fw-bold ">Why Choose Us</h1>
          </div>
          <div>
            {" "}
            <h6 className="fw-light poppins">What we do for you</h6>
          </div>
        </div>
        <div className=" d-flex justify-content-center flex-column  text-center p-5 home-margin">
          <div className="d-flex justify-content-center flex-row ">
            <h1 className="text-center text-primary text-blue ">
              Where Happiness Lives
            </h1>
          </div>
          <div className="d-flex justify-content-center flex-column ">
            <div className=" d-flex justify-content-center home-margin">
              <p className="text-center pb-5 ">
                Our mission is to engage in issues that are of concern to
                individuals, families and communities through an uncompromising
                commitment to create outstanding living, work and leisure
                environments.
              </p>
            </div>
            <div className="row row-cols-1 row-cols-lg-2 row-cols-md-2 px-lg-5 g-4 ">
              {services.length <= 8
                ? services?.map((s) => (
                    <div className="col" key={s.id}>
                      <ServiceCard
                        id={s.id}
                        author={s.author}
                        title={s.title}
                        description={s.description}
                        image={s.image}
                      />
                    </div>
                  ))
                : services.slice(0, 8).map((s) => (
                    <div className="col" key={s.id}>
                      <ServiceCard
                        id={s.id}
                        author={s.author}
                        title={s.title}
                        description={s.description}
                        image={s.image}
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column">
        <Footer />
      </div>
    </div>
  );
};

export default Service;
