import "./filter.css";
import NavBar from "../NavBar/nav";
import Footer from "../Footer/footer";
import FilterBox from "./filterBox";
import { Link } from "react-router-dom";

const FilterSell = () => {
  return (
    <div className="d-flex justify-content-center flex-column   ">
      <div className=" fixed-top ">
        <NavBar />
      </div>
      <div className=" h-100 d-flex flex-column filter-content">
        <div className="d-flex  flex-column justify-content-center filter-margin" >
          <div className="d-flex flex-column justify-content-center align-items-center mb-5">
              <p className="text-white text-center display-4 fw-bolder">Find Your Best Property</p>
          </div>

          <div className="d-flex justify-content-center flex-column ">
          <div className=" form-tab d-flex flex-row ">
              <div><Link to={"/home/filter-buy" } ><button className="filter-tab-btn   btn-radius1" data-tab-btn>Buy</button></Link></div>
              <div><Link to={"/home/filter-sell" } ><button className="filter-tab-btn active  " data-tab-btn>Sell</button></Link></div>
              <div><Link to={"/home/filter-rent" } ><button className="filter-tab-btn btn-radius2" data-tab-btn>Rent</button></Link>  </div>
             
             
          </div>

            <FilterBox/>

            
          </div>        
        </div>      
      </div>
      <div className="">
         <Footer/>
      </div>
      
    </div>
  );
};
export default FilterSell;
