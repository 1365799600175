import userImg from "../../assets/image/Others/userImg.png";
import LineImg from "../../assets/image/Others/Line.png";
import userIcon2 from "../../assets/image/Others/Profile.png";
import propertyIcon from "../../assets/image/Others/Add Property.png";
import favouriteIcon from "../../assets/image/Others/FavoriteIcon2.png";
import logoutIcon from "../../assets/image/Others/logout.png";
import EditIcon from "../../assets/image/Others/editIcon.svg";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Components/context/AuthContext";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProfileInfo = ({ handlelogout }: any) => {
  const items = JSON.parse(localStorage.getItem("user") || "{}");
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    // console.log("My acc tok", items.tokens.refresh);
  }, [user]);
  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.removeItem("user");
    navigate("/");
  };
  return (
    <div className="d-flex flex-column card mt-5 mb-5 ">
      <div className="d-flex flex-column  ">
        <div className="d-flex justify-content-center align-items-end">
          <img
            className="d-flex justify-content-end align-items-center imglogo navbar-brand"
            src={userImg}
            alt="logo"
            style={{
              height: "100px",
              width: "100px",
              borderRadius: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="d-flex justify-content-center text-center fs-4 fw-bold">
          {items?.username}
        </div>
        <div className="d-flex justify-content-center text-center text-dark fs-6">
          {items?.email}
        </div>
        <div className="d-flex justify-content-center mt-3">
          {" "}
          <img src={LineImg} alt="" />{" "}
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center  mt-3 text-dark button-tab m-4  gap-1">
        <div className="d-flex flex-row align-items-center p-1 gap-2  hover-btn  active rounded-1  ">
          <div className="d-flex align-items-center ">
            <img src={userIcon2} alt="" />
          </div>
          <div className="d-flex align-items-center ">
            <Link to="/login/my-account" className="active  ">
              My Account
            </Link>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center p-1 gap-2 rounded-1 hover-btn ">
          <div className="d-flex align-items-center  ">
            <img src={propertyIcon} alt="" />
          </div>
          <div className="d-flex align-items-center ">
            <Link to="/login/add-property" className="text-dark hover-btn">
              Add property
            </Link>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center p-1 gap-2 rounded-1 hover-btn">
          <div className="d-flex align-items-center ">
            <img src={favouriteIcon} alt="" />
          </div>
          <div className="d-flex align-items-center">
            <Link to="/login/my-account" className="text-dark hover-btn">
              My Favorite
            </Link>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center p-1 gap-2 rounded-1 hover-btn">
          <div className="d-flex align-items-center ">
            <img src={favouriteIcon} alt="" />
          </div>
          <div className="d-flex align-items-center">
            <Link to="/login/my-account" className="text-dark hover-btn">
              Change Password
            </Link>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center p-1 gap-2 rounded-1 hover-btn">
          <div className="d-flex align-items-center ">
            <img src={logoutIcon} alt="" />
          </div>
          <div className="d-flex align-items-center ">
            <Link to="/" className="text-dark hover-btn" onClick={handleLogout}>
              Logout
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileInfo;
