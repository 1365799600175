import axios from "axios";
import { LoginStart } from "./Components/context/AuthActions";
import { AuthContext } from "./Components/context/AuthContext";
import { AuthReducer } from "./Components/context/AuthReducer";
import { LoginSuccess } from "./Components/context/AuthActions";

export const loginCall = async (userCredentials, dispatch) => {
  console.log("inside login Call");
  dispatch({ type: "LOGIN_START" });
  try {
    console.log("inside try ", userCredentials);
    console.log(userCredentials.email);
    console.log(userCredentials.password);

    const res = await axios({
      method: "post",
      url: "https://realestate.api.arisaftech.co.jp/api/v2/login",
      data: {
        email: userCredentials.email,
        password: userCredentials.password,
      },
    });
    console.log("api calls ", res.data.tokens.access);
    const obj = {
      id: res.data.user_info.id,
      username: res.data.user_info.username,
      email: res.data.user_info.email,
      is_verified: res.data.user_info.is_verified,
      tokens: {
        refresh: res.data.tokens.refresh,
        access: res.data.tokens.access,
      },
    };
    console.log(res.data);
    if(res.data) {
      localStorage.setItem("user", JSON.stringify(obj));
    }
    

    const data = JSON.parse(localStorage.getItem(localStorage.getItem("user")));
    console.log("local storage ", data);
    // user["tokens"] = res.data;
    // dispatch(LoginSuccess(res.data));
    dispatch({ type: "LOGIN_SUCCESS", payload: obj });
    console.log("after dispatch");
  } catch (err) {
    dispatch({ type: "LOGIN_FAILURE", payload: err });
  }
};
