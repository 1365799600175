import "./filter.css";
import NavBar from "../NavBar/nav";
import Footer from "../Footer/footer";
import FilterBox from "./filterBox";
import { Link } from "react-router-dom";



const FilterBuy = () => {
  return (
    <div className="d-flex justify-content-center flex-column   ">
      <div className=" fixed-top ">
        <NavBar />
      </div>
      <div className=" h-100 d-flex flex-column filter-content">
        <div className="d-flex  flex-column justify-content-center filter-margin" >
          <div className="d-flex flex-column justify-content-center align-items-center mb-5">
              <p className="text-white text-center display-4 fw-bolder">Find Your Best Property</p>
          </div>

          <div className="d-flex justify-content-center flex-column ">
             <div className=" form-tab d-flex flex-row ">
              <div><Link to={"/home/filter-buy" } ><button className="filter-tab-btn active  btn-radius1" data-tab-btn>Buy</button></Link></div>
              <div><Link to={"/home/filter-sell" } ><button className="filter-tab-btn  " data-tab-btn>Sell</button></Link></div>
              <div><Link to={"/home/filter-rent" } ><button className="filter-tab-btn btn-radius2" data-tab-btn>Rent</button></Link>  </div>
             
             
          </div>
            
          <FilterBox/>
          {/* <div className=" d-flex flex-column p-3  flex-fill gap-3 box-bg  ">           
              <div className=" d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3 ">           
               
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="property-type" id="property-type" className="dropdown-list w-100 textarea p-2 ">
                <option value="#">Property Type</option>
                  <option value="#">Apartment</option>
                  <option value="#">House</option>
                  <option value="#">Offices</option>
                  <option value="#">Townhome</option>
                </select>
               
                </div>
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                <option value="#">Property City</option>
                  <option value="#">New York, USA</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>
                </div>
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                <option value="#">Property Area</option>
                  <option value="#">New York, USA</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>     
                </div>
              </div>
              <div className=" d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3 ">
                
                <div className=" w-100   d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                <option value="#">Bedrooms</option>
                  <option value="#">New York, USA</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>
               
                </div>
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                  <option value="#">Max Size</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>
                </div>
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                <option value="#">Min Size</option>
                  <option value="#">New York, USA</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>     
                </div>
              </div>
              <div className=" w-100 d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3 ">
               
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                  <option value="#">Max Price</option>
                  <option value="#">New York, USA</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>
                
                </div>
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                  <option value="#">Min Price</option>
                  <option value="#">New York, USA</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>
                
                </div>
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                  <input className="filter-tab-btn search w-100 p-2 rounded-1"type="submit"value="Search"/>

                </div>
              </div>
             
          </div> */}
            
          </div>
          
        </div>
        
      </div>
      <div className="">
         <Footer/>
      </div>
      
    </div>
  );
};
export default FilterBuy;
