import React from "react";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import userImg from "../../assets/image/Others/userImg.png";
import ReviewIcon from "../../assets/image/PropertyImg/Rivew Icon.png";
import RatingSystem from "./Rating";

const PropertyComment = () => {
  return (
    <div className="d-flex w-100 line">
      <div className="d-flex flex-column  gap-2 mb-3 ">
        <div className="d-flex justify-content-between mb-2">
          <div className="d-flex gap-2">
            <img
              className="d-flex justify-content-end align-items-center imglogo navbar-brand"
              src={userImg}
              alt="logo"
              style={{
                height: "70px",
                width: "70px",
                borderRadius: "100%",
                objectFit: "cover",
              }}
            />
            <div className="d-flex flex-column mt-1">
              <div className="fs-5 text-dark fw-bold">Robert Fox</div>
              <h6 className="text-muted">14 Augest, 2022</h6>
            </div>
          </div>
          <div className="d-flex ">
            <RatingSystem />
          </div>
        </div>
        <div className="d-flex text-start flex-column">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis
          sit itaque cupiditate possimus exercitationem. Ducimus officiis
          distinctio tempora consequuntur repudi, assumenda rem nesciunt velit
          et!
        </div>
      </div>
    </div>
  );
};

export default PropertyComment;
