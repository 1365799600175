import "./filter.css";




const FilterBox = () => {
  return (
    
            

          <div className=" d-flex flex-column p-3  flex-fill gap-3 box-bg  ">           
              <div className=" d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3 ">           
               
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="property-type" id="property-type" className="dropdown-list w-100 textarea p-2 ">
                <option value="#">Property Type</option>
                  <option value="#">Apartment</option>
                  <option value="#">House</option>
                  <option value="#">Offices</option>
                  <option value="#">Townhome</option>
                </select>
               
                </div>
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                <option value="#">Property City</option>
                  <option value="#">New York, USA</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>
                </div>
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                <option value="#">Property Area</option>
                  <option value="#">New York, USA</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>     
                </div>
              </div>
              <div className=" d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3 ">
                
                <div className=" w-100   d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                <option value="#">Bedrooms</option>
                  <option value="#">New York, USA</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>
               
                </div>
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                  <option value="#">Max Size</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>
                </div>
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                <option value="#">Min Size</option>
                  <option value="#">New York, USA</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>     
                </div>
              </div>
              <div className=" w-100 d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3 ">
               
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                  <option value="#">Max Price</option>
                  <option value="#">New York, USA</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>
                
                </div>
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                
                <select name="location" id="location" className="dropdown-list w-100 textarea p-2">
                  <option value="#">Min Price</option>
                  <option value="#">New York, USA</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>
                
                </div>
                <div className=" w-100  d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3">
                  <input className="filter-tab-btn search w-100 p-2 rounded-1"type="submit"value="Search"/>
                </div>
              </div>
             
          </div>
            
          
  );
};
export default FilterBox;
