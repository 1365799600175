import "./homeContent.css";
import Search from "../Search/search";
import Login from "../Login/login";
import HeroSection from "../HeroSection/hero";
import WhatLooking from "../WhatLooking/whatlooking";
import { AuthContext, AuthContextProvider } from "../context/AuthContext";
import { useContext, useState } from "react";
import SignUp from "../SignUp/signup";

const HomeContent = () => {
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [registerModal, setRegisterModal] = useState<boolean>(false);

  const toggleLogin = () => {
    setLoginModal(true);
  };

  const toggleRegister = () => {
    setRegisterModal(true);
  };

  return (
    <div className="nav-container  ">
      <div className="body-content d-flex flex-fill  ">
        <div className="d-flex flex-column justify-content-center fyd-parent" style={{marginTop:"-60px"}}>
        <div className="fyd d-flex justify-content-center">
          <p>Find Your Dream Home</p>
        </div>
        <div className="paragraph ">
            We are recognized for exceeding client expectations and delivering
            great results through dedication, ease of process, and extraordinary
            services to our worldwide clients.
        </div>
        <div className="log-button d-flex justify-content-center gap-2">
          <div className="nav-button d-flex justify-content-center">
            <button
             onClick={toggleLogin}
              type="button"
              className="login btn1 btn-lg d-lg-none"
            >
              Login
            </button>
          </div>
          <div className="nav-button d-flex justify-content-center">
            <button
              onClick={toggleRegister}
              type="button"
              className="btn2 btn-lg d-lg-none"
            >
              SignUp
            </button>
          </div>

        </div>
        </div>
      </div>
      {loginModal && (
        <AuthContextProvider>
          <Login setloginModal={setLoginModal}/>
        </AuthContextProvider>
      )}

      {registerModal && <SignUp setRegisterModal={setRegisterModal} />}
      
      
    </div>
  );
};

export default HomeContent;
