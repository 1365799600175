import React, { useState } from 'react';
import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';

const RatingSystem: React.FC = () => {
  const [rating, setRating] = useState(0);

  const handleRating = (selectedRating: number) => {
    setRating(selectedRating);
  };

  return (
    <div>
    
      <div className="rating" style={{color: "#F7941D" }} >
        {[1, 2, 3, 4, 5].map((value) => (
          <span
            key={value}
            onClick={() => handleRating(value)}
            className="star"
          >
            {rating >= value ? (
              <BsStarFill />
            ) : rating + 0.5 === value ? (
              <BsStarHalf />
            ) : (
              <BsStar />
            )}
          </span>
        ))}
      </div>
    </div>
  );
};

export default RatingSystem;
