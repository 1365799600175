import React, { useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// const ResetPassword = ({setloginModal}:any) => {
const ChangePassword = ({setChangePassword , setCongrates}: any) => {

    const handleSubmit = async (e: any) => {
        e.preventDefault();
       
      };
      const handleCongrates = () => {
        setChangePassword(false);
        setCongrates(true);
       }

  return (
    <>
      
      <div className="Auth-form-container" onClick={() => setChangePassword(false)}>
      <form className="Auth-form d-flex justify-content-center align-items-center"  onClick={(e)=>{e.stopPropagation()}}>
        <div className="Auth-form-content ">
          
          <div className="d-flex justify-content-center flex-column text-center">
            <h3 className="Auth-form-title">Update Your Password</h3>
            <p className="text-muted">Please type your new password</p>
          </div>
         
          
          <div className="form-input mt-2 ">
              <i className="fa fa-lock text-muted"></i>
              <input
                type="password"
                className="password form-control mt-1"
                placeholder="Enter Your Password"
                required
                
                minLength={6}
              />
            </div>
            <div className="form-input mt-2  mb-4 ">
              <i className="fa fa-lock text-muted"></i>
              <input
                type="password"
                className="password form-control mt-1"
                placeholder="Confirm your Password"
                required
                
                minLength={6}
              />
            </div>
          <div className="d-grid gap-2 mt-4 mb-4">
            <button type="submit" className="btn btn-primary p-2 " onClick={handleCongrates}>
              Update Password
            </button>
          </div>
                    
        </div>
      </form>
    </div>
    
    </>
  );
};
export default ChangePassword;
