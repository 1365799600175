import "./clientTestimonials.css";
import MsgSign from '../../assets/image/Testimonials/msgSign.png'
import ClientImg1 from "../../assets/image/Testimonials/client1.png";
import ClientImg2 from "../../assets/image/Testimonials/client2.png";
import ClientImg3 from "../../assets/image/Testimonials/client3.png";

import Line from "../../assets/image/Testimonials/Line.png";
import Img5 from "../../assets/image/whatlooking/img5.png";
import Img9 from "../../assets/image/whatlooking/img6.png";

const ClientTestimonials = () => {
  return (
    
    <div className="bg d-flex judtify-content-center align-items-center flex-column   ">
        <div className="home-margin mb-5">
        <div className="whatlooking text-center d-flex judtify-content-center align-items-center flex-column mb-2 ">
            <div className="d-flex justify-content-center">
                <h3 className="msg1">Client Testimonials</h3>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <p className="msg2 text-center">Publish the best of your client testimonials and let the world know what a great agent or real estate agency you are</p>
            </div> 
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center margin-bottom">
            <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 px-lg-5 g-4 ">
                <div className="col">
                    <div className="card h-100 d-flex justify-content-center align-items-center box-shaddow">
                        <div className="d-flex justify-content-center img-size" >
                            <img src={MsgSign} className="card-img-top img-fluid" alt="..."/>
                        </div>
                        <div className="card-body  d-flex justify-content-center ">
                            <p className="card-text text-muted text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo placeat fugiat quia dicta nobis. Repudiandae delectus maiores neque minima, beatae ipsum quo totam nisi laudantium consectetur accusantium! Eaque, animi rem.</p>                            
                        </div>
                        <div className="d-flex justify-content-center ">
                            <img src={Line} className="card-img-top" alt="..."/>
                        </div>
                        <div className=" d-flex justify-content-center flex-column img-size ">
                            <div className=""> 
                                <img src={ClientImg1} className="card-img-top img-fluid" alt="..."/>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center flex-column">      
                            <h6 className="text-dark text-center">Liza Simpson</h6>                             
                            <p className="views text-muted">New York, USA</p>                            
                         </div>
                    </div> 
                </div>
                <div className="col">
                    <div className="card h-100 d-flex justify-content-center align-items-center box-shaddow ">
                        <div className="d-flex justify-content-center img-size">
                            <img src={MsgSign} className="img-fluid card-img-top" alt="..."/>
                        </div>                        
                        <div className="card-body  d-flex align-items-center">
                            <p className="card-text text-muted text-center">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quia perspiciatis deserunt neque doloremque dolores. Commodi voluptates eaque voluptatibus consequuntur iste vel in nam nulla, itaque assumenda suscipit perferendis iusto ducimus!</p>                            
                        </div>
                        <div className="d-flex justify-content-centere">
                            <img src={Line} className="card-img-top" alt="..."/>
                        </div>
                        <div className=" d-flex justify-content-center flex-column img-size   ">
                            <div className=""> 
                                <img src={ClientImg2} className="card-img-top img-fluid" alt="..."/>
                            </div>                                                        
                        </div>
                        <div className="d-flex justify-content-center flex-column">                               
                            <h6 className="text-dark text-center">Carolina V.</h6>
                            <p className="views text-muted">New York, USA</p>                              
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100 d-flex justify-content-center align-items-center box-shaddow ">
                        <div className="d-flex justify-content-center img-size">
                            <img src={MsgSign} className="img-fluid card-img-top" alt="..."/>
                        </div>                        
                        <div className="card-body  d-flex align-items-center">
                            <p className="card-text text-muted text-center">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quia perspiciatis deserunt neque doloremque dolores. Commodi voluptates eaque voluptatibus consequuntur iste vel in nam nulla, itaque assumenda suscipit perferendis iusto ducimus!</p>                            
                        </div>
                        <div className="d-flex justify-content-centere">
                            <img src={Line} className="card-img-top" alt="..."/>
                        </div>
                        <div className=" d-flex justify-content-center flex-column img-size   ">
                            <div className=""> 
                                <img src={ClientImg3} className="card-img-top img-fluid" alt="..."/>
                            </div>                                                        
                        </div>
                        <div className="d-flex justify-content-center flex-column">                               
                            <h6 className="text-dark text-center">Mark Leon</h6>
                            <p className="views text-muted">New York, USA</p>                              
                        </div>
                    </div>
                </div>
            </div>                      
        </div> 
        </div>   
    </div>
  );
};
export default ClientTestimonials;
