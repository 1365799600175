import "./myAccount.css";

import EditIcon from "../../assets/image/Others/editIcon.svg"
import { Link } from "react-router-dom";

const EditInfo = () => {
  return (
      
        <div className="d-flex flex-column mt-5 mb-5  flex-grow-1 fill-flex contact-margin" >
          <div className="d-flex flex-column justify-content-center ">
              <div className="d-flex flex-row justify-content-between " >
                <div className="d-flex flex-column fw-bold fs-5 mt-2 mb-2"> My Account Information</div>
               
              </div>
              <div className="d-flex flex-column justify-content-center  gap-3">
                <div className="d-flex flex-row justify-content-center gap-3   ">
                  <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                    <h6 className="h6 bg "> First Name </h6>
                    <input className="textarea d-flex w-100 bg no-outline placeholder-margin"type="text"placeholder=""/>
                  </div>
                  <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                    <h6 className="h6 bg "> Last Name</h6>
                    <input className="textarea d-flex w-100 bg placeholder-margin"type="text"placeholder=""/>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center gap-3   ">
                  <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                    <h6 className="h6 bg ">Date Of Birth</h6>
                    <input className="textarea d-flex w-100 bg no-outline placeholder-margin"type="text"placeholder=""/>
                  </div>
                  <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                    <h6 className="h6 bg w-25">Gender</h6>
                    <input className="textarea d-flex w-100 bg placeholder-margin"type="text"placeholder=""/>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center gap-3   ">
                  <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                    <h6 className="h6 bg w-50">Contact Number</h6>
                    <input className="textarea d-flex w-100 bg no-outline placeholder-margin"type="text"placeholder=""/>
                  </div>
                  <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                    <h6 className="h6 bg ">Email Address</h6>
                    <input className="textarea d-flex w-100 bg placeholder-margin"type="text"placeholder=""/>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center gap-3   ">
                  <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                    <h6 className="h6 bg w-25">NID No</h6>
                    <input className="textarea d-flex w-100 bg no-outline placeholder-margin"type="text"placeholder=""/>
                  </div>
                  <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                    <h6 className="h6 bg ">Passport No</h6>
                    <input className="textarea d-flex w-100 bg placeholder-margin"type="text"placeholder=""/>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center gap-3   ">
                  <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                    <h6 className="h6 bg w-25 ">Description</h6>
                    <textarea
                    className="textarea w-100 bg p-3"
                    placeholder="Type your short details or description"
                    rows={2}
                  ></textarea>
                  </div>
                  
                </div>
              </div>
          </div>
          <div>
            <div className="d-flex flex-column justify-content-center mt-4">
                <div className="d-flex flex-row justify-content-between " >
                    <div className="d-flex flex-column fw-bold fs-5 mt-2 mb-2"> Address</div>
                </div>
                <div className="d-flex flex-column justify-content-center  gap-3">
                    <div className="d-flex flex-row justify-content-center gap-3   ">
                    <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                        <h6 className="h6 bg w-25"> Division </h6>
                        <input className="textarea d-flex w-100  bg no-outline placeholder-margin "type="text"placeholder="Enter your Division"/>
                    </div>
                    <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                        <h6 className="h6 bg w-25"> District</h6>
                        <input className="textarea d-flex w-100 bg placeholder-margin"type="text"placeholder="Enter your District"/>
                    </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center gap-3   ">
                    <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                        <h6 className="h6 bg w-50">Sub-District(Upazila)</h6>
                        <input className="textarea d-flex w-100 bg no-outline placeholder-margin"type="text"placeholder="Enter your sub District"/>
                    </div>
                    <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                        <h6 className="h6 bg w-25 ">Area</h6>
                        <input className="textarea d-flex w-100 bg placeholder-margin"type="text"placeholder="Enter Area"/>
                    </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center gap-3   ">
                    <div className="border-text border border-secondary textarea d-flex flex-column w-100 ">
                    <h6 className="h6 bg w-25 ">Address</h6>
                    <textarea className="textarea w-100 bg p-2"placeholder="Enter road no, block/sector"rows={2}></textarea>
                    </div>  
                </div>
                </div>
            </div>
          </div>
          <Link to={"/login/my-account"}>
          <div className="d-flex flex-column flex-lg-row justify-content-evenly fill-flex mt-3 ">
            <input className="profile-tab-btn search w-100 p-2 rounded-2"type="submit"value="Save"/>
          </div>
          </Link>
        </div>
       
  );
};
export default EditInfo;
