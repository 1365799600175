import { useContext, useEffect, useState } from "react";
import NewLatestCard from "./newLatestCard";
import PropertiesDetails from "./propertiesList";
import axios from "axios";
import { Link } from "react-router-dom";
import "./latest.css"

type propertyType = {
  id: string;
  title: string;
  size: string;
  location: string;
  rooms: number;
  baths: number;
  price: number;
  description: string;
  image1: string | undefined;
};
const Latest = () => {
  const [latest, setLatest] = useState<propertyType[] | []>([]);
  
  useEffect(() => {
    const fetchproperties = async () => {
      const res = await axios.get(
        // "https://realestate.api.arisaftech.co.jp/api/v1/property/latest"
        "http://5.189.160.223:8001/api/v2/latest"
      );
      console.log("inside latest ", res.data);

      setLatest(res.data);
    };
    fetchproperties();
  }, []);
  return (
    <>
      <div className="latestContainer d-flex flex-column px-lg-5  ">
        <div className="home-margin">
        <div
          className="latestHeader d-flex flex-column text-center"
          style={{ color: "#1F53B9" }}
        >
          <div className="d-flex justify-content-center">
            <h3 className="msg1">Latest Properties</h3>
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ color: "black" }}
          >
            {" "}
            <p className="msg2">
              You can create the list with any properties you wish to diplay
            </p>{" "}
          </div>
        </div>
        <div className="container gap-2" style={{ width: "100%" }}>
          <div className="row d-flex flex-row">
            {latest?.map((d) => (
              <div
                className="col-sm-12 mt-3 col-md-6 col-lg-4 f-flex flex-row"
                key={d.id}
              >

                <NewLatestCard
                  id={d.id}
                  title={d.title}
                  size={d.size}
                  location={d.location}
                  price={d.price}
                  rooms={d.rooms}
                  description={d.description}
                  baths={d.baths}
                  propertyImg={d.image1}
                />      
              </div>
            ))}
          </div>
        </div>
        <div className="nav-button d-flex justify-content-center p-5 ">
        <button
          //   onClick={ }
          type="button"
          className=" btn btn-primary btn-lg "
        >
          <Link to={"/home/property-list"} className=" text-light">View All</Link>
        </button>
      </div>
        </div>
      </div>
    </>
    
  );
};

export default Latest;
