import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./Pages/Home/home";
import { AuthContextProvider } from "./Components/context/AuthContext";
import Contact from "./Pages/Contact/contact";
import Filter from "./Components/Filter/filterBuy";
import BookAppointment from "./Components/BookAppointment/bookAppointment";
import MyAccount from "./Pages/My Account/myAccount";
import SingleBlog from "./Pages/Blog/singleBlog";

function App() {
  return (
    <AuthContextProvider>
      <Home />
      {/* <MyAccount /> */}
      {/* <SingleBlog /> */}
      {/* <Contact /> */}
    </AuthContextProvider>
  );
}

export default App;
