import "./realEstateBlog.css";
// import BlogImg1 from "../../assets/image/BlogImg/blogImg1.png";
// import BlogImg2 from "../../assets/image/BlogImg/blogImg2.png";
// import BlogImg3 from "../../assets/image/BlogImg/blogImg3.png";
// import Img4 from "../../assets/image/whatlooking/img4.png";
// import Img5 from "../../assets/image/whatlooking/img5.png";
// import Img9 from "../../assets/image/whatlooking/img6.png";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import BlogCard from "../../Pages/Blog/blogCard";
import axios from "axios";
import BlogList from "../../Pages/Blog/blogList";

type blogType = {
  id: string;
  author: string;
  categories: [];
  title: string;
  body: string;
  created_at: string;
  updated_at: string;
};

const RealEstateBlog = () => {
  const [blogs, setBlogs] = useState<blogType[] | []>([]);
  useEffect(() => {
    const fetchBlogs = async () => {
      const res = await axios.get("http://5.189.160.223:8001/api/v2/post/");
      console.log("Inside blog", res.data.results);
      setBlogs(res.data.results);
    };
    fetchBlogs();
  }, []);
  return (
    <>
    <div className="bg d-flex judtify-content-center align-items-center flex-column  ">
      <div className="home-margin">
      <div className="whatlooking text-center d-flex judtify-content-center align-items-center flex-column ">
        <div className="d-flex justify-content-center">
          <h3 className="msg1">Our Real Estate Blog</h3>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <p className="msg2 text-center">
            Read the latest news from the real estate industry as it happens
          </p>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center  ">
        <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 px-lg-5 g-4 ">
          {blogs?.slice(0, 3).map((b) => (
            <div className="col">
              <BlogCard
                id={b.id}
                author={b.author}
                title={b.title}
                categories={b.categories}
                body={b.body}
                created_at={b.created_at}
                updated_at={b.updated_at}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="nav-button d-flex justify-content-center p-5 margin-bottom ">
        <button
          //   onClick={ }
          type="button"
          className=" btn btn-primary btn-lg "
        >
          <Link to={"/blogs"} className=" text-light" >View All</Link>
        </button>
      </div>
      </div>
    </div>
    </>
  );
};
export default RealEstateBlog;
