import "./home.css";
import NavBar from "../../Components/NavBar/nav";
import SignUp from "../../Components/SignUp/signup";
import SignUpForm from "../../Components/SignUp/signup";
import WhyUs from "../../Components/whyUs/whyUs";
import Latest from "../../Components/Latest/latest";
import HeroSection from "../../Components/HeroSection/hero";
import WhatLooking from "../../Components/WhatLooking/whatlooking";
import HomeContent from "../../Components/HomeContent/homeContent";
import ClientTestimonials from "../../Components/ClientTestimonials/clientTestimonials";
import RealEstateBlog from "../../Components/RealEstateBlog/realEstateBlog";
import Footer from "../../Components/Footer/footer";
import Partners from "../../Components/Partners/partners";
import { useLocation } from "react-router-dom";
import NewLatestCard from "../../Components/Latest/newLatestCard";
import { AuthContext } from "../../Components/context/AuthContext";
import { useContext, useEffect, useState } from "react";

const Home = () => {
  const loc = useLocation();

  console.log(loc);
  const { user, isFetching, error, dispatch } = useContext(AuthContext);
  useEffect(() => {
    console.log("inside home", user?.tokens.refresh);
    console.log(user?.email);
    console.log("Home user ", user);
    const items = JSON.parse(localStorage.getItem("user") || "{}");
    console.log(typeof items);

    console.log("local home ", items);
    console.log(items?.email);

    console.log(typeof user);
  }, [user]);
  return (
    <>
      <NavBar />
      <HomeContent />
      <HeroSection />
      <WhyUs />
      <WhatLooking />
      <Latest />
      <ClientTestimonials />
      <Partners />
      <RealEstateBlog />
      <Footer />
    </>
  );
};

export default Home;
