import "./addProperty.css";
import NavBar from "../NavBar/nav";
import Footer from "../Footer/footer";
import SellPost1 from "./sellPost1";
import SellPost2 from "./sellPost2";

import { Link } from "react-router-dom";
import { useState } from "react";

const AddProperty = () => {
  
  
  return (
    <div className=" d-flex flex-column bg mt-5 ">
      <div className=" fixed-top ">
        <NavBar />
      </div>
      <div className="d-flex justify-content-center flex-column home-margin padding ">
        <div className="fw-vold fs-1 d-flex justify-content-center text-center mt-3">Welcome Md Solaiman Ovi ! Let's Add Your Property</div>
        <div className="button-tab d-flex flex-row justify-content-center mt-3 mb-3">
              
              <button className="hover-btn active tab-btn" data-tab-btn>Sell</button>
              <button className="hover-btn   tab-btn" data-tab-btn>Rent</button>
            </div>
            <SellPost1/> 
      </div>
      <div className="d-flex flex-column">
        <Footer />
      </div>
    </div>
  );
};
export default AddProperty;
