
import NavBar from "../NavBar/nav";
import Footer from "../Footer/footer";


import { Link } from "react-router-dom";
import { useState } from "react";

const Policy = () => {
  
  
  return (
    <div className=" d-flex flex-column bg mt-5 ">
      <div className=" fixed-top ">
        <NavBar />
      </div>
      <div className="d-flex justify-content-center flex-column home-margin padding ">
        <div className="d-flex  justify-content-center line fs-2 ">Privacy Policy</div>
        <div className="d-flex flex-column justify-content-center mb-5 ">
            <text className="fs-6">
        <br/><br/><b>1. Introduction</b><br/>
            At [Company Name], we are committed to protecting the privacy and security of our clients' personal information. This privacy policy outlines how we collect, use, and protect the information you provide to us when you engage our services.

            
        <br/><br/> <b>2. Information We Collect</b><br/>

        We collect personal information that you provide to us when you engage our services. This may include your name, address, email address, phone number, and other contact information. We may also collect information about your property, including its location, size, and amenities.
        <br/><br/> <b>3. Use of Information</b> <br/>

            We use the information we collect to provide our real estate services to you. This includes marketing your property, communicating with you about potential buyers or properties, and facilitating transactions. We may also use your information for internal business purposes, such as analyzing market trends and improving our services.

        <br/><br/> <b>4. Disclosure of Information</b><br/><br/>

            We may disclose your personal information to third parties, such as potential buyers, other real estate agents, and service providers who assist us in providing our services to you. We will only disclose your information to third parties who have agreed to maintain the confidentiality of your information.

        <br/><br/> <b>5. Security of Information</b><br/>

            We take reasonable steps to protect the personal information we collect from unauthorized access, use, or disclosure. We use industry-standard security measures, such as encryption and firewalls, to protect your information.

        <br/><br/><b>6. Retention of Information</b><br/>

            We retain your personal information for as long as necessary to provide our services to you and to comply with legal obligations. We may also retain your information for internal business purposes, such as recordkeeping and analysis.

        <br/><br/><b>7. Your Rights</b><br/>

            You have the right to access and correct the personal information we hold about you. You may also request that we delete your information, although we may be required to retain certain information for legal or business purposes.

        <br/><br/><b>8. Changes to this Policy</b><br/>

            We may update this privacy policy from time to time. We will notify you of any material changes to this policy by posting a notice on our website or by contacting you directly.

        <br/><br/> <b>9. Contact Us</b><br/>

            If you have any questions or concerns about this privacy policy, please contact us at [Company Contact Information].
            </text>
        </div>
       
      </div>
      <div className="d-flex flex-column">
        <Footer />
      </div>
    </div>
  );
};
export default Policy;
