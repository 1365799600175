import NavBar from "../NavBar/nav";
import Footer from "../Footer/footer";
import userImg from "../../assets/image/Others/userImg.png";

import { Link } from "react-router-dom";
import { useState } from "react";

const SellerDetails = () => {
  return (
    <div className=" d-flex flex-column bg mt-5 ">
      <div className=" fixed-top ">
        <NavBar />
      </div>
      <div className="d-flex justify-content-center flex-column home-margin padding gap-5 ">
        <div className="d-flex gap-5 ">
            <div className=" d-flex flex-column justify-content-center w-100 ">
                <div className="d-flex flex-column w-100 p-2 ">
                        <div className="d-flex flex-column justify-content-center gap-4 mb-3 line">
                            <div className="d-flex justify-content-center mb-2">
                                <img
                                className="d-flex justify-content-end align-items-center imglogo navbar-brand"
                                src={userImg}
                                alt="logo"
                                style={{
                                    height: "100px",
                                    width: "100px",
                                    borderRadius: "100%",
                                    objectFit: "cover",
                                }}
                                />
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="d-flex flex-column justify-content-center ">
                                    <div className="fs-5 fw-bold text-dark">Darlene Robertson</div>
                                    <h6 className="text-muted text-center">seller</h6>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex justify-content-between line">
                                <h6 className="text-dark">Contact No.</h6>
                                <h6 className="text-muted">(603) 555-0123</h6>
                            </div>
                            <div className="d-flex justify-content-between line">
                                <h6 className="text-dark">Email</h6>
                                <h6 className="text-muted">wikinerd@verizon.net</h6>
                            </div>
                            <div className="d-flex justify-content-between line">
                                <h6 className="text-dark">Gender</h6>
                                <h6 className="text-muted">Male</h6>
                            </div>
                            <div className="d-flex justify-content-between line">
                                <h6 className="text-dark">Date of Birth</h6>
                                <h6 className="text-muted">01/01/1990</h6>
                            </div>
                            <div className="d-flex justify-content-between line">
                                <h6 className="text-dark">Location</h6>
                                <h6 className="text-muted">Dhaka, Bangladesh</h6>
                            </div>
                        </div>
                </div>
            </div>
            
            <div className="d-flex flex-column w-100 gap-2">
                <div className="line">
                    <h3>Seller Details</h3>
                </div>
                <div className="mt-2">
                    Main Street is a stunning example of classic architecture and modern
                    luxury. This exquisite property is situated on a large, beautifully
                    landscaped lot and features a spacious, open floor plan perfect for
                    entertaining. The home boasts an abundance of natural light, high
                    ceilings, and stunning hardwood floors throughout. The gourmet
                    kitchen is a chef's dream, featuring top-of-the-line appliances,
                    custom cabinetry, and a large center island perfect for meal prep
                    and casual dining. The master suite is a true retreat, complete with
                    a spa-like en-suite bathroom, large walk-in closet,
                </div>
                <div className="d-flex mt-5 ">
                    <button type="button" className="d-flex gap-2 btn btn-primary w-100 ">
                        <i className="fa-solid fa-house gap-2 fa-2x" style={{color: "#fdfcfc"}}></i>
                        <h6 className="mt-1">05 Listings</h6>
                    </button>
                </div>
            </div>
        </div>
        <div className="list">
            <h3 className="text-primary">Active Listings</h3>
            <div>

            </div>
        </div>
       
      </div>
      <div className="d-flex flex-column">
        <Footer />
      </div>
    </div>
  );
};
export default SellerDetails;
