import React, { useState } from "react";
import 'react-calendar/dist/Calendar.css'
import { render } from "react-dom";
import Calendar from "react-calendar";

const ReactCalendar = () => {
  const [date, setDate] = useState(new Date());

  const onChange = (date:any)  => {
    setDate(date);
  };

  return (
    <>
        <Calendar  onChange={onChange} value={date} />
    </>
  );
};
export default ReactCalendar;

// import { useState } from 'react';
// import Calendar from 'react-calendar';

// type ValuePiece = Date | null;

// type Value = ValuePiece | [ValuePiece, ValuePiece];

// function ReactCalendar() {
//   const [value, onChange] = useState<Value>(new Date());

//   return (
//     <div>
//       <Calendar onChange={onChange} value={value} />
//     </div>
//   );
// }

// export default ReactCalendar;