import "./whatlooking.css";
import Img1 from "../../assets/image/whatlooking/img1.png";
import Img2 from "../../assets/image/whatlooking/img2.png";
import Img3 from "../../assets/image/whatlooking/img3.png";
import Img4 from "../../assets/image/whatlooking/img4.png";
import Img5 from "../../assets/image/whatlooking/img5.png";
import Img6 from "../../assets/image/whatlooking/img6.png";
import axios from "axios";
import { useState, useEffect } from "react";

type categoryType = {
  id: string;
  title: string;
  num_of_elements: string;
  athor: string;
};
const WhatLooking = () => {
  const [categories, setCategories] = useState<categoryType[] | []>([]);
  useEffect(() => {
    const fetchCategories = async () => {
      const res = await axios.get("http://5.189.160.223:8001/api/v2/category");
      // console.log(res.data);
      setCategories(res.data);
    };
    fetchCategories();
  }, []);
  return (
    <div className="bg d-flex judtify-content-center align-items-center flex-column px-lg-5  ">
      <div className="home-margin mb-5 margin-top-bottom ">
      <div className="whatlooking d-flex judtify-content-center align-items-center text-center flex-column mt-3 ">
        <h3 className="msg1 ">Explore Your Dream Place </h3>
        <p className="msg2 text-center">Find your house in your city</p>
      </div>
      <div className="d-flex judtify-content-center align-items-center flex-row column-gap margin-bottom ">
        <div className="d-flex flex-column row-gap">
          <div className="d-flex flex-column apartment">
            <img className="img-fluid " src={Img1} alt="img1" />
            {/* <h4>Apartment</h4> */}


          </div>
          <div className="d-flex flex-row column-gap  ">
            <div className="d-flex justify-content-center ">
              <img className="img-fluid " src={Img2} alt="img2" />
              
              
            </div>
            <div className="d-flex justify-content-center">
              <img className="img-fluid " src={Img3} alt="img3" />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column row-gap ">
          <div className=" d-flex flex-row column-gap  ">
            <div className="d-flex justify-content-center">
              <img className="img-fluid" src={Img4} alt="img4" />
            </div>
            <div className="d-flex justify-content-center">
              <img className="img-fluid" src={Img5} alt="img5" />
            </div>
          </div>
          <div className=" d-flex flex-column">
            <img className="img-fluid" src={Img6} alt="img6" />
          </div>
        </div>
      </div>
      </div>
      {/* <!-- Stack the columns on mobile by making one full-width and the other half-width --> */}
    </div>
  );
};
export default WhatLooking;
