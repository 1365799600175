import React from "react";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import locationIcon from "../../assets/image/map-marker-Light.png";
import bed from "../../assets/image/bed.png";
import bath from "../../assets/image/bath.png";
import Size from "../../assets/image/Ft icon.png";
import Line1 from "../../assets/image/Line1.png"
import Line2 from "../../assets/image/Line2.png"
import FavIcon1 from "../../assets/image/Others/FavoriteIcon1.png";
import FavIcon3 from "../../assets/image/Others/FavoriteIcon3.png";


type propertyType = {
  id: string;
  title: string;
  size: string;
  location: string;
  rooms: number;
  baths: number;
  price: number;
  description: string;
  propertyImg: string | undefined;
};

const NewLatestCard = ({
  id,
  title,
  size,
  location,
  rooms,
  baths,
  price,
  description,
  propertyImg,
}: propertyType) => {

  const [beforeWish, setBeforeWish] = useState<Boolean> (true);
  const [afterWish, setAfterWish] = useState<Boolean> (false);
  const saveWish = () => {
    setBeforeWish(!beforeWish);
    setAfterWish(!afterWish);
  }

  
  


  return (
    <div className="card latestPropertyCard h-100 d-flex justify-content-center box-shaddow">     
      <div className=""><Link to="/home/property-details"><img src={propertyImg} className=" img-fluid object-fit-cover rounded-2" style={{height:"200px", width:"400px"}} alt="..." /></Link></div>  
      <div className="d-flex flex-row justify-content-between">
        <div></div>
        <div className="wish-icon " onClick={saveWish} >
          {beforeWish && <div ><img src={FavIcon1} className=" img-fluid " alt="..." /></div>}
          {afterWish && <div ><img src={FavIcon3} className=" img-fluid " alt="..." /></div>}         
        </div>
        
        

      </div>
      
      <div className="card-body d-flex justify-content-center text-start flex-column">
        <div className="d-flex flex-column ">
          <div><h6 className="card-title text-dark">{title}</h6></div>
          <small className=" fw-normal text-muted mb-2"style={{ fontSize: "10px" }} >
                <img src={locationIcon} alt="" /><span> {location}</span>
          </small>
        </div>
        
        <div className="views d-flex flex-column">
        <div className="d-flex flex-row justify-content-between"style={{ fontSize: "10px" }}>
            <div className="bed d-flex  justify-content-between gap-1 text-muted">
              <div>
                <img className="img-fluid " src={bed} alt="" /> <span>{rooms} Bed</span>
              </div>
              
            </div>
            <div> <img className="img-fluid  " src={Line1} alt="" /></div>
               
            <div className="bath d-flex flex-row justify-content-center gap-1 text-muted">
              <div>
              <img className="img-fluid " src={bath} alt="" /><span> {baths} Bath</span>

              </div>
              
            </div>
            <div> <img className="img-fluid  " src={Line1} alt="" /></div>
            <div className="size d-flex justify-content-center gap-1 text-muted">
              <img className="img-fluid " src={Size} alt="" />
                <span>{size}ft<sup>2</sup></span>
            </div>
            <div> <img className="img-fluid  " src={Line1} alt="" /></div>
            <div className="price d-flex justify-content-center gap-1 text-dark fw-bold">
              <img className="img-fluid  " src="" alt="" /> <span> $ {price} Year</span>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div>
                <img className="img-fluid  " src={Line2} alt="" />
            </div>   
            <div>
                <p className=" text-muted fs-6 mb-0 ">{description}</p>
            </div>  

          </div>
                        
        </div>
        
        
        
      </div>
    </div>
  );
};

export default NewLatestCard;
