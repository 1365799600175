import React from "react";
import './about.css'
import NavBar from "../../Components/NavBar/nav";
import Footer from "../../Components/Footer/footer";
import AboutImg1 from '../../assets/image/About/aboutImg1.png'
import AboutImg2 from '../../assets/image/About/aboutImg2.png'
import AboutCard from "./aboutCard";

const About = () => {
  return (
    <div>
      <div className="fixed-top">
        <NavBar />
      </div>
      <div className="d-flex  flex-column justify-content-center align-items-center ">
        <div className="d-flex flex-column justify-content-center about-content   ">
          {/* <img className="img-fluid " src={ContactCoverImg} alt="" /> */}
          <div className="about-title d-flex justify-content-center text-center p-1 ">
            Our Mission
          </div>
          <div className=" about-msg d-flex justify-content-center align-items-center d-none d-sm-block">
            {/* <h6 className=" m-2 text-center text-light w-100"> */}
              Our mission is to empower consumers with information to make smart
              Decisions. Realtyspace is a real estate marketplace dedicated to
              Helping homeowners, home buyers, sellers, renters and agents find
              And share information about homes, real estate and home
              Improvement.
            {/* </h6> */}
          </div>
          <div className=" about-msg d-flex justify-content-center align-items-center d-block d-sm-none ">
            {/* <h6 className=" m-2 text-center text-light w-100"> */}
              Our mission is to empower consumers with information to make smart
              Decisions.
            {/* </h6> */}
          </div>
        </div>
        <div className="bg">
        <div className="d-flex justify-content-start flex-column  text-center home-margin ">
        <div className="d-flex justify-cintent-center ">
        <div className=" d-flex flex-row p-5 parent padding  row row-cols-1 row-cols-lg-2 row-cols-md-1 ">
          <div className="d-flex justify-cintent-center align-items-center ">
            <div className="d-flex justify-cintent-center align-items-center ">
              <img className="img-fluid   " src={AboutImg1} alt="" />
            </div>            
          </div>
          <div className="d-flex flex-column justify-content-start align-items-center  flex-shrink-2">
            <div>
              <p className="fw-bold fs-1 text-start mt-5 ">We Provide The Best <span className="text-primary" >Property</span>  For You.</p>
            </div>
            <div>
                <p className="h5  fw-normal text-start text-muted ">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequuntur optio, ea eos dolore nihil est voluptates eaque delectus quo similique sequi? Architecto, animi expedita! Nisi, reprehenderit. Voluptate corporis fugit voluptatem.
                </p>
                <div className="d-flex gap-5 mt-3 ">
                  <div className="fw-bold"> <a href="">EXPLORE MORE</a></div>
                  <div className="d-flex justify-content-center mt-1"><i className="fas fa-arrow-right"></i></div>                    
                </div>
            </div>
            
          </div>
        </div>

        </div>
          <div className="d-flex justify-content-center flex-column flex-shrink-1 mt-2 mb-5">
            <div className="row row-cols-1 row-cols-lg-2 row-cols-md-2 px-lg-5 g-4 ">
              <div className="col">
                <AboutCard/>
              </div>
              <div className="col">
                <AboutCard/>
              </div>
              <div className="col">
                <AboutCard/>
              </div>
              <div className="col">
                <AboutCard/>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default About;
