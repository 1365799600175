import React from "react";
import ServiceImg1 from "../../assets/image/Service/serviceImg1.png";

type serviceType = {
  id: string;
  author: string;
  title: string;
  description: string;
  image: string | undefined;
};
const ServiceCard = ({
  id,
  author,
  title,
  description,
  image,
}: serviceType) => {
  return (
    <div className="card h-100 d-flex justify-content-center box-shaddow">
      <img src={image} className="card-img-top img-fluid" alt="..." />
      <div className="card-body text-start d-flex justify-content-center flex-column">
        <h5 className="card-title text-blue fw-bold fs-2">{title}</h5>
        <p className="card-text text-muted ">{description}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
