import React, { useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ResetOTP from "./resetOTP";

// const ResetPassword = ({setloginModal}:any) => {
const ResetPassword = ({setForget, setOtp}: any) => {
  
  
  
    const handleSubmit = async (e: any) => {
        e.preventDefault();
       
      };

    // const [resetModal, setResetPasswordModal] = useState<boolean>(false);
    // const handlePopUpSubmit = () => {
    //   setloginModal(false);
    // }
   
     const handleOtp = () => {
      setForget(false);
      setOtp(true);
     }
 

  return (
    <>
      
      <div className="Auth-form-container" onClick={() => setForget(false)}>
      <form className="Auth-form d-flex justify-content-center align-items-center"  onClick={(e)=>{e.stopPropagation()}}>
        <div className="Auth-form-content ">
          
          <div className="d-flex justify-content-center flex-column text-center">
            <h3 className="Auth-form-title" >Reset Password</h3>
            <p className="text-muted">Enter the email address associated with your account</p>
          </div>
         
          
          <div className="d-flex justify-content-center form-input mt-4  mb-4">
            
            <i className="icon-top fa-regular fa-envelope text-muted"></i>
            <input
              type="text"
              className=" username form-control"
              placeholder="Enter Your Email" 
            />
           
          </div>
          <div className="d-grid gap-2 mt-4 mb-4" >
            <button type="submit" className="btn btn-primary p-2  " onClick={handleOtp}>
              Reset Password
            </button>
          </div>
                    
        </div>
      </form>
    </div>
    


    </>
  );
};
export default ResetPassword;
