import React from "react";
import BlogImg1 from "../../assets/image/BlogImg/blogImg1.png";
import PropertyComment from "../../Components/Latest/propertyComment";
import RatingSystem from "../../Components/Latest/Rating";
import AddReview from "../../Components/Latest/review";

const BlogCardDetails = () => {
  return (
    <div className="d-flex flex-column gap-5 bg-light p-2">
      <div className="d-flex flex-column gap-3 ">
        <div>
          <img src={BlogImg1} className="card-img-top img-fluid" alt="..." />
        </div>
        <div className="d-flex flex-column ">
          <h3 className="d-flex text-dark">Selling Your Home</h3>
          <h6 className="d-flex text-muted">
            Posted by admin on May Aug 21, 2022
          </h6>
        </div>
        <div className=" d-flex justify-content-center text-start flex-column">
          <div className="poppins text-muted text-light">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum. Sed ut
            perspiciatis unde omnis iste natus error sit voluptatem accusantium
            doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
            inventore veritatis et quasi architecto beatae vitae dicta sunt
            explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur
            aut odit aut fugit, sed quia consequuntur magni dolores eos qui
            ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
            dolorem. Duis aute irure dolor in reprehenderit in voluptate velit
            esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum. Sed ut perspiciatis unde omnis iste natus error
            sit voluptatem.
          </div>
          <div className="poppins text-muted text-start ">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum. Sed ut
            perspiciatis unde omnis iste natus error sit voluptatem accusantium
            doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
            inventore veritatis et quasi architecto beatae vitae dicta sunt
            explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur
            aut odit aut fugit, sed quia consequuntur magni dolores eos qui
            ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
            dolorem. <br />
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum. Sed ut perspiciatis unde omnis iste natus error
            sit voluptatem accusantium.
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <h4 className="d-flex">Tags: </h4>
            <h6 className="text-muted">House, Travel</h6>
          </div>
          <div></div>
        </div>
      </div>
      <div className="d-flex flex-column gap-5 mt-3 ">
        <PropertyComment />
        <PropertyComment />
        <AddReview />
      </div>
    </div>
  );
};

export default BlogCardDetails;
