import "./myAccount.css";
import NavBar from "../../Components/NavBar/nav";
import Footer from "../../Components/Footer/footer";
import userImg from "../../assets/image/Others/userImg.png";
import LineImg from "../../assets/image/Others/Line.png";
import userIcon2 from "../../assets/image/Others/Profile.png";
import propertyIcon from "../../assets/image/Others/Add Property.png";
import favouriteIcon from "../../assets/image/Others/FavoriteIcon2.png";
import logoutIcon from "../../assets/image/Others/logout.png";
import EditIcon from "../../assets/image/Others/editIcon.svg";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Components/context/AuthContext";
import { useContext } from "react";
import { useEffect } from "react";
import EditInfo from "./editInfo";
import VisibleInfo from "./visibleInfo";
import ProfileInfo from "./profileInfo";

const MyAccount = ({ handlelogout }: any) => {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    console.log("inside acc", user?.tokens.refresh);
    console.log(user?.email);
    console.log("inside acc ", user);

    console.log(typeof user);
  }, [user]);

  console.log("my acc", user);
  return (
    <div className=" d-flex flex-column bg mt-5 ">
      <div className=" fixed-top ">
        <NavBar />
      </div>
      <div className="mt-5  d-flex flex-row justify-content-center gap-5  mb-5 home-margin ">
        <div className="container">
          <div className="row">
            <div className="col-3 col-md-3 col-12">
              <ProfileInfo />
            </div>
            <div className="d-flex flex-grow-1 col-9 col-md-9 col-12">
              <VisibleInfo />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column">
        <Footer />
      </div>
    </div>
  );
};
export default MyAccount;
