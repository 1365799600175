import React from "react";
import BlogImg1 from "../../assets/image/BlogImg/blogImg1.png";
import { Link } from "react-router-dom";

type blogType = {
  id: string;
  author: string;
  categories: [];
  title: string;
  body: string;
  created_at: string;
  updated_at: string;
};

const BlogCard = ({
  id,
  author,
  categories,
  title,
  body,
  created_at,
  updated_at,
}: blogType) => {
  return (
    <div className="card h-100 d-flex justify-content-center box-shaddow">
        <Link to={"/blogs/blog-details"}><img src={BlogImg1} className="card-img-top img-fluid" alt="..." /></Link>
      <div className="card-body d-flex justify-content-center text-start flex-column">
        <h5 className="card-title text-dark">{title}</h5>
        <div className="views text-muted ">
          {created_at}
          <i className="far fa-clock text-info"></i> 14h ago
        </div>
        <p className="card-text text-muted lorem-padding">{body}</p>
        <div className="d-flex justify-content-between mt-3 ">
          <div>
            {" "}
            <Link to={"/blogs/blog-details"}>Read More</Link>
          </div>
          <div className="d-flex justify-content-center mt-1">
            <Link to={"/blogs/blog-details"}><i className="fas fa-arrow-right"></i></Link>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
