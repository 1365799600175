import "./contact.css";
import NavBar from "../../Components/NavBar/nav";
import ContactCoverImg from "../../assets/image/Others/contact_Img.png";
import Footer from "../../Components/Footer/footer";

const Contact = () => {
  return (
    <div className=" d-flex flex-column  ">
      <div className=" fixed-top ">
        <NavBar />
      </div>
      <div className="d-flex  flex-column justify-content-center align-items-center  bg">
        <div className="d-flex flex-column justify-content-center contact-content">
          <div className="contact-title"> Contact Us</div>
        </div>
        <div className=" d-flex justify-content-center flex-column text-center  home-margin">
          <div className="d-flex justify-content-center align-items-center contact-message ">
            <span className="text-center ">Send a </span>
            <span className="text-center " style={{color:"rgba(31, 83, 185, 1)"}}>
              &nbsp;Message
            </span>
          </div>
          
            <div className="d-flex justify-content-center flex-column ">
              <div className=" d-flex justify-content-center contact-sub-msg text-center mb-5 contact-margin">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Fermentum est est risus ultrices. At ullamcorper turpis
                  gravida magna tincidunt vel, lectus in nisl. Temporibus autem
                  quibusdam et aut officiis debitis is aut rerum necessitatibuse
                  in saepes eveniet ut etes seo lage voluptates repudiandae sint
                  et molestiae non mes for Creating futures through building
                  pres Creating.
              </div>
              <div className="d-flex flex-column contact-margin ">
                <div className="list-unstyled d-flex flex-column flex-lg-row justify-content-evenly fill-flex flex-sm-column gap-3 ">
                  <input
                    className="textarea d-flex w-100  p-2 "
                    type="text"
                    placeholder="  Your Name"
                  />

                  <input
                    className="textarea d-flex w-100 p-2 "
                    type="text"
                    placeholder="  Email"
                  />

                  <input
                    className="textarea d-flex w-100 p-2 "
                    type="text"
                    placeholder="  Subject"
                  />
                </div>
                <div className="mt-3 w-100 d-flex flex-column p-1">
                  <textarea
                    className="textarea w-100"
                    placeholder="  Message"
                    rows={5}
                  ></textarea>
                </div>
                <div className="d-flex justify-content-center justify-content-lg-end mt-4 mb-2">
                  <input
                    className="btn btn-primary  "
                    type="submit"
                    value="Submit"
                  />
                </div>
              </div>
            </div>
          
        </div>
      </div>
      <div className="d-flex flex-column">
        <Footer />
      </div>
    </div>
  );
};
export default Contact;
