import React, { useRef, useState } from "react";
import "./signup.css";
import Img7 from "../../assets/image/img7.svg";
import Logo from '../../assets/image/Home/logo.jpg'
import Gmail from '../../assets/image/Others/Gmail.png'
import Facebook from '../../assets/image/Others/Facebook.png'
import ResetOTP from "../ForgotPassword/resetOTP";
import { AuthContext, AuthContextProvider } from "../context/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Login from "../Login/login";
import { faL } from "@fortawesome/free-solid-svg-icons";

const SignUp = ({ setSignUp, overlaySignOutClose }: any) => {
  const userNameRef = useRef<HTMLInputElement>(null);
  const userEmailRef = useRef<HTMLInputElement>(null);
  const userPasswordRef = useRef<HTMLInputElement>(null);
  const userPassword2Ref = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [otp, setOtp] = useState<boolean>(false);
  const toggleLogin = () => {
    setLoginModal(true);
  };
  const SignOutClose = () =>{
    setOtp(true);
    setSignUp(true);
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (userPasswordRef === userPassword2Ref) {
      const res = await axios({
        method: "post",
        url: "https://realestate.api.arisaftech.co.jp/api/v2/register",
        data: {
          email: userEmailRef.current?.value,
          username: userNameRef.current?.value,
          password: userPasswordRef.current?.value,
        },
      });
      if (res.data.email) {
        navigate("/login");
      } else {
        navigate("/");
      }
    }
  };

  return (
    <>
      {/* <div className="Auth-form-container" onClick={()=>{setRegisterModal(false)}}> */}
      <div
        className="Auth-form-container"
        onClick={() => {
          setSignUp(false);
        }}
      >
        <form
          className="Auth-form d-flex justify-content-center align-items-center "
          onSubmit={handleSubmit}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="Auth-form-content ">
            <div className=" d-flex justify-content-center">
              <img className=" logo1" src={Logo} alt="logo" />
            </div>
            <div className="d-flex justify-content-center flex-column text-center">
              <h3 className="Auth-form-title">Sign Up</h3>
              <p className="text-muted">Hey, good to see you again</p>
            </div>
            <div className="form-input">
              <i className="fa fa-user text-muted"></i>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Name"
                name="useName"
                ref={userNameRef}
                required
              />
            </div>

            <div className="form-input mt-1">
              <i className="fa-regular fa-envelope text-muted"></i>
              <input
                type="email"
                className="username form-control   "
                placeholder="Enter Your Email"
                ref={userEmailRef}
                required
              />
            </div>

            <div className="form-input mt-1">
              <i className="fa fa-lock text-muted"></i>
              <input
                type="password"
                className="password form-control mt-1"
                placeholder="Enter Your Password"
                required
                ref={userPasswordRef}
                minLength={6}
              />
            </div>
            <div className="form-input mt-1">
              <i className="fa fa-lock text-muted"></i>
              <input
                type="password"
                className="password form-control mt-1"
                placeholder="Confirm Your Password"
                required
                ref={userPasswordRef}
                minLength={6}
              />
            </div>
            <div className="d-flex flex-row justify-content-between ">
              <div className="form-check ">
                <label className="form-check-label text-muted">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name=""
                    id=""
                    value="checkedValue"
                  />
                  I agree to the{" "}
                  <a className="text-decoration-underline" href="#">
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a className="text-decoration-underline" href="#">
                    Terms & Conditions
                  </a>{" "}
                  of Real Estate
                </label>
              </div>
            </div>
            <div className="d-grid gap-2 mt-4">
              <button type="submit" className="btn btn-primary p-2">
                Sign Up
              </button>
            </div>
            <div className="d-flex justift-content-center align-items-center flex-column mt-1">
              <div>Or Sign in with</div>
              <div className="">
                <img className=" " src={Gmail} alt="gmail" />
                <img className=" " src={Facebook} alt="facebook" />
              </div>
            </div>
            <div>
              <p className=" text-center text-muted ">
                Already have an account? &nbsp;
                <a
                  className="registration fw-bold"
                  onClick={overlaySignOutClose}
                  href="#"
                >
                  Sign In
                </a>
              </p>
            </div>
          </div>
        </form>
      </div>
      {/* {loginModal && (
        <AuthContextProvider>
          <Login setloginModal={setLoginModal}/>
        </AuthContextProvider>
      )} */}
    {otp && <ResetOTP setOtp={setOtp} /> }


    </>
  );
};
export default SignUp;
