import "./partners.css";
import PartnerImg1 from "../../assets/image/Partners/partner1.png";
import PartnerImg2 from "../../assets/image/Partners/partner2.png";
import PartnerImg3 from "../../assets/image/Partners/partner3.png";
import PartnerImg4 from "../../assets/image/Partners/partner4.png";




const Partners = () => {
  return (
    <div className=" d-flex   flex-column home-margin ">
        <div className="whatlooking d-flex judtify-content-center text-center align-items-center flex-column">
            
            <h3 className="msg1">Our Partners</h3>
            <p className="msg2">We only work with the best companies in the globe</p>
        </div>
      
        <div className="d-flex flex-row justify-content-center align-items-center margin-bottom ">
        <div className=" row row-cols-1 row-cols-lg-4 row-cols-md-2 px-lg-5 g-4 mb-5 ">
        <div className="col">
                <div className="card h-100 d-flex justify-content-center align-items-center box-shaddow">
                <img src={PartnerImg1} className="card-img-top img-width img-fluid" alt="..."/>

                </div>
            </div>
            <div className="col">
                <div className="card h-100 d-flex justify-content-center align-items-center box-shaddow">
                <img src={PartnerImg2} className="card-img-top img-width img-fluid" alt="..."/>

                </div>
            </div>
            <div className="col">
                <div className="card h-100 d-flex justify-content-center align-items-center box-shaddow">
                <img src={PartnerImg3} className="card-img-top img-width img-fluid" alt="..."/>

                </div>
            </div>
            <div className="col">
                <div className="card h-100 d-flex justify-content-center align-items-center box-shaddow">
                <img src={PartnerImg4} className="card-img-top img-width img-fluid" alt="..."/>

                </div>
            </div>
            
            </div>                      
        </div>    

        </div>

   
    
  );
};
export default Partners;