import React from "react";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import userImg from "../../assets/image/Others/userImg.png";
import ReviewIcon from "../../assets/image/PropertyImg/Rivew Icon.png";
import RatingSystem from "./Rating";
const AddReview = () => {
  return (
    <div className="d-flex flex-column w-100 gap-2 ">
      <div className="d-flex justify-content-start flex-column gap-2">
        <div className="d-flex ">
          <h3>Add a Review</h3>
        </div>
        <div className="d-flex">
          <RatingSystem />
        </div>
      </div>

      <div className="form-input">
        <i className="fa fa-user text-muted"></i>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Your Name"
          name="Full Name"
        />
      </div>

      <div className="form-input mt-1">
        <i className="fa-regular fa-envelope text-muted"></i>
        <input
          type="email"
          className="username form-control  "
          placeholder="Email Address"
        />
      </div>
      <div className="form-input mt-1 border-2">
        <textarea
          className="textarea border w-100  "
          style={{ border: "1px solid #ced4da;" }}
          placeholder=" Comment"
          rows={5}
        ></textarea>
      </div>
      <div className="form-check ">
        <label className="form-check-label text-muted">
          <input
            type="checkbox"
            className="form-check-input"
            name=""
            id=""
            value="checkedValue"
          />
          Save my name, email, and website in this browser for the next time I
          comment
        </label>
      </div>
      <div className="d-grid gap-2 mt-4">
        <button type="submit" className="btn btn-primary p-2">
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddReview;
