import React from 'react'
import AboutImg3 from '../../assets/image/About/aboutImg3.png'

const AboutCard = () => {
  return (
          
        <div className="card  d-flex justify-content-center box-shaddow">
            <img src={AboutImg3} className="card-img-top image img-fluid" alt="..."/>
            <div className="card-body d-flex justify-content-center text-start flex-column">
                <h5 className="card-title text-dark">Why live in New York</h5>
                
                <p className="card-text text-muted ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo placeat fugiat quia dicta nobis. Repudiandae delectus maiores neque minima.</p>
                
            </div>
        </div>
       
      
   
  )
}

export default AboutCard
