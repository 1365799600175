

import { Link } from "react-router-dom";

const SellPost1 = () => {
  return (
    <div>
               
            <div className="d-flex flex-column justify-content-center gap-3  ">
                <div className="d-flex flex-row w-50 sm-screen-size gap-5 row row-cols-2 ">
                    <div className=" w-100  d-flex flex-column   flex-sm-column gap-1">
                        <div><h5>please select city or Division</h5></div>
                        <div>
                            <select name="property-type" id="property-type" className="dropdown-list w-100 textarea p-2 ">                       
                            <option value="#">Dhaka</option>
                            <option value="#">House</option>
                            <option value="#">Offices</option>
                            <option value="#">Townhome</option>
                            </select>
                        </div>                  
                    </div>
                    <div className=" w-100  d-flex flex-column   flex-sm-column gap-1">
                        <div><h5>please select city or Division</h5></div>
                        <div>
                            <select name="property-type" id="property-type" className="dropdown-list w-100 textarea p-2 ">                       
                            <option value="#">Dhaka</option>
                            <option value="#">House</option>
                            <option value="#">Offices</option>
                            <option value="#">Townhome</option>
                            </select>
                        </div>             
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center  gap-5 mt-3">
                    <div className="d-flex flex-column  w-100  gap-3">
                        <div className=" w-100  d-flex flex-column  justify-content-center  flex-sm-column gap-1">
                            <div><h5>Bed Rooms</h5></div>
                            <div>
                                <select name="property-type" id="property-type" className="dropdown-list w-100 textarea p-2 ">                       
                                <option value="#">Dhaka</option>
                                <option value="#">House</option>
                                <option value="#">Offices</option>
                                <option value="#">Townhome</option>
                                </select>
                            </div>                  
                        </div>
                        <div className=" w-100  d-flex flex-column  justify-content-center  flex-sm-column gap-1">
                            <div><h5>Kitchen</h5></div>
                            <div>
                                <select name="property-type" id="property-type" className="dropdown-list w-100 textarea p-2 ">                       
                                <option value="#">Dhaka</option>
                                <option value="#">House</option>
                                <option value="#">Offices</option>
                                <option value="#">Townhome</option>
                                </select>
                            </div>             
                        </div>
                        <div className=" w-100  d-flex flex-column  justify-content-center  flex-sm-column gap-1">
                            <div><h5>Belcony</h5></div>
                            <div>
                                <select name="property-type" id="property-type" className="dropdown-list w-100 textarea p-2 ">                       
                                <option value="#">Dhaka</option>
                                <option value="#">House</option>
                                <option value="#">Offices</option>
                                <option value="#">Townhome</option>
                                </select>
                            </div>             
                        </div>
                        
                    </div>
                    <div className="d-flex flex-column  w-100 gap-3">
                        <div className=" w-100  d-flex flex-column  justify-content-center  flex-sm-column gap-1">
                            <div><h5>Bath Rooms</h5></div>
                            <div>
                                <select name="property-type" id="property-type" className="dropdown-list w-100 textarea p-2 ">                       
                                <option value="#">Dhaka</option>
                                <option value="#">House</option>
                                <option value="#">Offices</option>
                                <option value="#">Townhome</option>
                                </select>
                            </div>                  
                        </div>
                        <div className=" w-100  d-flex flex-column  justify-content-center  flex-sm-column gap-1">
                            <div><h5>Size (sqft)</h5></div>
                            <div>
                                <select name="property-type" id="property-type" className="dropdown-list w-100 textarea p-2 ">                       
                                <option value="#">Dhaka</option>
                                <option value="#">House</option>
                                <option value="#">Offices</option>
                                <option value="#">Townhome</option>
                                </select>
                            </div>             
                        </div>
                        <div className=" w-100  d-flex flex-column  justify-content-center  flex-sm-column gap-1">
                            <div><h5>Facing</h5></div>
                            <div>
                                <select name="property-type" id="property-type" className="dropdown-list w-100 textarea p-2 ">                       
                                <option value="#">Dhaka</option>
                                <option value="#">House</option>
                                <option value="#">Offices</option>
                                <option value="#">Townhome</option>
                                </select>
                            </div>             
                        </div>
                        
                    </div>   
                </div>
                {/* <div className="d-flex flex-row justify-content-start  gap-5 mt-3 w-100 ">
                    <div className="d-flex flex-column justify-content-center gap-3 w-100 row-cols-1 row-cols-lg-2 row-cols-md-1">
                        <div className="d-flex flex-column justify-content-center gap-1  ">                         
                            <div><h5>Tittle</h5></div>
                            <div className=" w-100 d-flex flex-column ">
                                <input className="textarea d-flex w-100 p-2 "type="text"placeholder=" Keep it short"/>
                            </div>                           
                        </div>
                        <div className="d-flex flex-column justify-content-center gap-1 w-100  ">                          
                            <div><h5>Facility</h5></div>
                            <div className="  d-flex flex-column justify-content-start  ">
                                <div className="form-check d-flex flex-row row row-cols-2 row-cols-lg-4 row-cols-md-4 w-100 gap-1">
                                            <label className="form-check-label d-flex gap-1 fs-6 ">
                                                <input type="checkbox"className="form-check-input" name="" id=""value="checkedValue"/>
                                                CCTV
                                            </label>
                                            <label className="form-check-label d-flex gap-1 fs-6">
                                                <input type="checkbox"className="form-check-input" name="" id=""value="checkedValue"/>
                                                Garage
                                            </label>                         
                                             <label className="form-check-label d-flex gap-1 fs-6">
                                                <input type="checkbox"className="form-check-input" name="" id=""value="checkedValue"/>
                                                Swimming Pool
                                            </label>
                                            <label className="form-check-label d-flex gap-1 fs-6">
                                                <input type="checkbox"className="form-check-input" name="" id=""value="checkedValue"/>
                                                Garden
                                            </label>
                                </div>
                            </div>         
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center gap-1  gap-3 w-100 ">                           
                        <div><h5>Address (optional)</h5></div>
                            <div className="mt-3 w-100 d-flex flex-column ">
                                <textarea
                                    className="textarea w-100 p-3"
                                    placeholder=" Enter Road No, block/sector"
                                    rows={4}
                                ></textarea>
                            </div>                             
                    </div>
                </div> */}
                <div className="container">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="d-flex flex-column justify-content-center gap-1  ">                         
                            <div><h5>Tittle</h5></div>
                            <div className=" w-100 d-flex flex-column ">
                                <input className="textarea d-flex w-100 p-2 "type="text"placeholder=" Keep it short"/>
                            </div>                           
                        </div>
                        <div className="d-flex flex-column justify-content-center gap-1 w-100">                          
                            <div><h5>Facility</h5></div>
                            <div className="  d-flex flex-column justify-content-start  ">
                                <div className="form-check d-flex flex-row row row-cols-2 row-cols-lg-4 row-cols-md-4 w-100 gap-1">
                                            <label className="form-check-label d-flex gap-1 fs-6 ">
                                                <input type="checkbox"className="form-check-input" name="" id=""value="checkedValue"/>
                                                CCTV
                                            </label>
                                            <label className="form-check-label d-flex gap-1 fs-6">
                                                <input type="checkbox"className="form-check-input" name="" id=""value="checkedValue"/>
                                                Garage
                                            </label>                         
                                             <label className="form-check-label d-flex gap-1 fs-6">
                                                <input type="checkbox"className="form-check-input" name="" id=""value="checkedValue"/>
                                                Swimming Pool
                                            </label>
                                            <label className="form-check-label d-flex gap-1 fs-6">
                                                <input type="checkbox"className="form-check-input" name="" id=""value="checkedValue"/>
                                                Garden
                                            </label>
                                </div>
                            </div>         
                        </div>
                    </div>
                    <div className="col-md-6 col-12">                           
                        <div><h5>Address (optional)</h5></div>
                            <div className="mt-3 w-100 d-flex flex-column ">
                                <textarea
                                    className="textarea w-100 p-3"
                                    placeholder=" Enter Road No, block/sector"
                                    rows={4}
                                ></textarea>
                            </div>                             
                    </div>
                </div>
                </div>

                <div className="d-flex flex-column w-100 gap-1 mb-3">
                        <div><h5>Description</h5></div>
                        <div className=" w-100 d-flex flex-column ">
                            <textarea
                                className="textarea w-100 "
                                placeholder=" More details- more interested buyers!"
                                rows={5}
                            ></textarea>
                        </div>       
                </div>
                <div className="d-flex justify-content-end mb-5 gap-5">
                    <Link to="/login/add-property2" >
                        <button type="button" className="btn btn-outline-primary px-5 ">Next   <i className="fas fa-arrow-right " ></i></button>
                    </Link>
                        
                </div>
            </div> 
    </div>
  );
};
export default SellPost1;
