import "./myAccount.css";
import NavBar from "../../Components/NavBar/nav";
import Footer from "../../Components/Footer/footer";
import { Link } from "react-router-dom";
import EditInfo from "./editInfo";
import VisibleInfo from "./visibleInfo";
import ProfileInfo from "./profileInfo";

const MyAccountEdit = () => {
  return (
    <div className=" d-flex flex-column bg mt-5 ">
      <div className=" fixed-top ">
        <NavBar />
      </div>
      <div className="mt-5  d-flex flex-row justify-content-center gap-5  mb-5 home-margin ">
        
          <div className="container">
              <div className="row">
                <div className="col-3 col-md-3 col-12">
                  <ProfileInfo/>
                </div>
                <div className="d-flex flex-grow-1 col-9 col-md-9 col-12">
                  <EditInfo/>
                </div>
              </div>
          </div>
      </div>
     
      <div className="d-flex flex-column">
        <Footer />
      </div>
      
    </div>
  );
};
export default MyAccountEdit;
