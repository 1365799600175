import { Link } from "react-router-dom";
import { useState } from "react";
import Calendar from "react-calendar";
import './bookAppointment.css'
import ReactCalendar from "./calenderComp";

  const BookAppointment = () => {
  return (
    <>
    <button type="button" className="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#exampleModal">
      Book Appointment
    </button>
    
      <div className="modal fade" id="exampleModal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog modal-dialog-centered modal-lg">
        
          <div className="modal-content bg">
            <div className="d-flex justify-content-end p-2 ">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className=" d-flex flex-column jusdtify-content-center align-items-center">     
                <h5 className="text-center text-dark mb-4"><b>Make an Appointment</b></h5>
                <div className="d-flex justify-content-center w-100 ">
                  <div className="w-75 d-flex gap-3">
                    <div className="w-75 d-flex justify-content-center">
                      <ReactCalendar/>
                    </div>
                    <div className=" d-flex flex-column justify-content-center w-50 gap-2 bg">
                      <div className="text-white bg-primary d-flex justify-content-center rounded-1  p-2">Select Time</div>
                      <div className="d-flex justify-content-between gap-3 ">
                        <div className="w-50 ">
                            <h6 className="bg-white p-2 rounded-1 text-dark select-time text-center">9:00 AM</h6>
                            <h6 className="bg-white p-2 rounded-1 text-dark select-time text-center">10:00 AM</h6>
                            <h6 className="bg-white p-2 rounded-1 text-dark select-time text-center">11:00 AM</h6>
                            <h6 className="bg-white p-2 rounded-1 text-dark select-time text-center">12:00 PM</h6>
                            <h6 className="bg-white p-2 rounded-1 text-dark select-time text-center">1:00 PM</h6>
                        </div>
                        <div className="w-50">
                            <h6 className="bg-white p-2 rounded-1 text-dark select-time text-center">2:00 PM</h6>
                            <h6 className="bg-white p-2 rounded-1 text-dark select-time text-center">3:00 PM</h6>
                            <h6 className="bg-white p-2 rounded-1 text-dark select-time text-center">4:00 PM</h6>
                            <h6 className="bg-white p-2 rounded-1 text-dark select-time text-center">5:00 PM</h6>
                            <h6 className="bg-white p-2 rounded-1 text-dark select-time text-center">6:00 PM</h6>

                        </div>

                      </div>

                    </div>
                  </div>
                  
                </div>
            </div>



            <div className="d-flex justify-content-end p-2">
              <button type="button" className="btn btn-dark ">Make Appointment</button>
            </div>
          </div>
        
        </div>
      </div>
    
    
</>
  );
};
export default BookAppointment;
