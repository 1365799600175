import React, { useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TickIcon from "../../assets/image/Others/Tick Circle.png"

// const ResetPassword = ({setloginModal}:any) => {
const Congratulations = ({setChangePassword, setCongrates}: any) => {

    const handleSubmit = async (e: any) => {
        e.preventDefault();
       
      };
    
  return (
    <>
      
      <div className="Auth-form-container" onClick={() => setCongrates(false)}>
      <form className="Auth-form d-flex justify-content-center align-items-center"  onClick={(e)=>{e.stopPropagation()}}>
        <div className="Auth-form-content ">
          
          <div className="d-flex justify-content-center align-items-center flex-column text-center">
            <div className="d-flex justify-content-center align-items-center ">
                <img className="   " src={TickIcon} alt="logo" />
            </div>
            <div><h3 className="Auth-form-title">Congratulations</h3></div>
            <div><p className="text-muted">You have created your new password</p></div>
            
          </div>      
                    
        </div>
      </form>
    </div>
    
    </>
  );
};
export default Congratulations;
