import React, { useState, useEffect } from "react";
import "./blog.css";
import NavBar from "../../Components/NavBar/nav";
import BlogCard from "../Blog/blogCard";
import CategoryCard from "./categoryCard";
import PostCard from "./postCard";
import Footer from "../../Components/Footer/footer";
import axios from "axios";
import BlogCardDetails from "./blogCardDetails";

type blogType = {
  id: string;
  author: string;
  categories: [];
  title: string;
  body: string;
  created_at: string;
  updated_at: string;
};

const BlogList = () => {
  const [blogs, setBlogs] = useState<blogType[] | []>([]);
  useEffect(() => {
    const fetchBlogs = async () => {
      const res = await axios.get("http://5.189.160.223:8001/api/v2/post/");
      console.log("Inside blog", res.data.results);
      setBlogs(res.data.results);
    };
    fetchBlogs();
  }, []);
  return (
    <div className="row row-cols-1 row-cols-lg-2 row-cols-md-2  g-3 ">
              {blogs.length <= 6
                ? blogs?.map((b) => (
                    <div className="col" key={b.id}>
                      <BlogCard
                        id={b.id}
                        author={b.author}
                        title={b.title}
                        categories={b.categories}
                        body={b.body}
                        created_at={b.created_at}
                        updated_at={b.updated_at}
                      />
                    </div>
                  ))
                : blogs?.slice(0, 8).map((b) => (
                    <div className="col" key={b.id}>
                      <BlogCard
                        id={b.id}
                        author={b.author}
                        title={b.title}
                        categories={b.categories}
                        body={b.body}
                        created_at={b.created_at}
                        updated_at={b.updated_at}
                      />
                    </div>
                  ))}
            </div>
  );
};

export default BlogList;
