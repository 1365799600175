import { useState, useEffect } from "react";
import NavBar from "../NavBar/nav";
import Footer from "../Footer/footer";
import axios from "axios";

import NewLatestCard from "./newLatestCard";

type propertyType = {
  id: string;
  title: string;
  size: string;
  location: string;
  rooms: number;
  baths: number;
  price: number;
  description: string;
  image1: string | undefined;
};
const PropertyList = () => {
  const [latest, setLatest] = useState<propertyType[] | []>([]);
  useEffect(() => {
    const fetchproperties = async () => {
      const res = await axios.get(
        // "https://realestate.api.arisaftech.co.jp/api/v1/property/latest"
        "http://5.189.160.223:8001/api/v2/latest"
      );
      console.log("inside latest ", res.data);
      setLatest(res.data);
    };
    fetchproperties();
  }, []);

  return (
   <div>
      <div className="fixed-top">
        <NavBar />
      </div>
      <div className="mt-5 d-flex  flex-column justify-content-center align-items-center ">
        <div className=" d-flex flex-column justify-content-center properties-content  ">
          {/* <img className="img-fluid " src={ContactCoverImg} alt="" /> */}
          <div>
            {" "}
            <h1 className="fw-bold">Latest Properties</h1>
          </div>
          <div>
            {" "}
            <p className="fs-6">
              You can create the list with any properties you wish to display
            </p>
          </div>
        </div>
        <div className=" d-flex justify-content-center flex-column  text-center p-5 home-margin">
          <div className="container gap-2" style={{ width: "100%" }}>
          <div className="row d-flex flex-row">
            {latest?.map((d) => (
              <div
                className="col-sm-12 mt-3 col-md-6 col-lg-4 f-flex flex-row"
                key={d.id}
              >
                {/* <LatestCard
                  id={d.id}
                  title={d.title}
                  size={d.size}
                  location={d.location}
                  price={d.price}
                  rooms={d.rooms}
                  description={d.description}
                  baths={d.baths}
                  propertyImg={d.image}
                /> */}

                <NewLatestCard
                  id={d.id}
                  title={d.title}
                  size={d.size}
                  location={d.location}
                  price={d.price}
                  rooms={d.rooms}
                  description={d.description}
                  baths={d.baths}
                  propertyImg={d.image1}
                />      
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column">
        <Footer />
      </div>
    </div>
    </div>
  );
};

export default PropertyList;
