import React, { useEffect, useState } from "react";
// import BlogImg1 from '../../assets/image/BlogImg/blogImg1.png'
import axios from "axios";
type catList = {
  id: number;
  name: string;
};
const CategoryCard = () => {
  const [cat, setCat] = useState<catList[] | []>([]);
  useEffect(() => {
    const fetchCat = async () => {
      const res = await axios.get(
        // "https://realestate.api.arisaftech.co.jp/api/v1/property/latest"
        "https://realestate.api.arisaftech.co.jp/api/v2/post/categories"
      );
      console.log("inside cat ", res.data);
      setCat(res.data);
    };
    fetchCat();
  }, []);
  return (
    <div className="card w-100 d-flex justify-content-start text-start flex-column box-shaddow">
      <div className="fs-5 fw-bold">Categories</div>
      {cat?.map((d) => (
        <div className="card-body  d-flex justify-content-between flex-row p-0">
          <div className="text-start">{d.name}</div>
          <div className="text-end">(2)</div>
        </div>
      ))}
    </div>
  );
};

export default CategoryCard;
