import "./hero.css";
import { Link } from "react-router-dom";
import FilterIcon from '../../assets/image/Home/filterIcon.png'
import FilterBuy from "../Filter/filterBuy";

const HeroSection = () => {
  return (
    
        <div className="hero-section container">
          <div className="hero-form-wrapper">
            <div className="button-tab d-flex flex-row ">
              <button className="hover-btn btn-radius1 tab-btn " data-tab-btn>Buy</button>
              <button className="hover-btn active tab-btn" data-tab-btn>Sell</button>
              <button className="hover-btn  btn-radius2 tab-btn" data-tab-btn>Rent</button>
            </div>
            <form action="" className="hero-form d-flex flex-row justify-content-around  bg">
              <div className="input-wrapper d-flex flex-column align-items-start  w-100 ">
                <label htmlFor="location" className="input-label ">Location</label>
                <select name="location" id="location" className="dropdown-list w-100">
                  <option value="#">New York, USA</option>
                  <option value="#">London, England</option>
                  <option value="#">Bangkok, Thailand</option>
                  <option value="#">Paris, France</option>
                  <option value="#">Dubai, UAE</option>
                  <option value="#">Dhaka, Bangladesh</option>
                </select>
              </div>
              <div className="input-wrapper d-flex flex-column align-items-start w-100 ">
                <label htmlFor="property-type" className="input-label ">Property Type</label>
                <select name="property-type" id="property-type" className="dropdown-list w-100">
                  <option value="#">Apartment</option>
                  <option value="#">House</option>
                  <option value="#">Offices</option>
                  <option value="#">Townhome</option>
                </select>
              </div>
              <div className="input-wrapper d-flex flex-column align-items-start w-100 ">
                <label htmlFor="max-price" className="input-label">Average Price</label>
                <select name="max-price" id="max-price" className="dropdown-list w-100">
                  <option value="#">$5000-10000</option>
                  <option value="#">$10000-20000</option>
                  <option value="#">$20000-50000</option>
                  <option value="#">$50000-100000</option>
                  <option value="#">$100000-1000000</option>               
                </select>
              </div>
              <div className="input-wrapper d-flex flex-row align-items-center w-100 gap-4 ">
                <Link to={"/home/filter-buy"} className="nav-link nav-menu-text" ><img className="border-0 img-border" src={FilterIcon} alt="" /></Link>
                
                <button type="submit"  className=" search-btn  px-3 py-2 poppins ">Search now</button>
              </div>
            </form>
          </div>
        </div>
      
  );
};

export default HeroSection;
