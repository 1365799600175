import React from "react";
import Login from "../Components/Login/login";
const Redirect = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default Redirect;
