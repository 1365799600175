import AddPhotos from '../../assets/image/Others/add_photos.png'

import { Link } from "react-router-dom";

const SellPost2 = () => {
  return (
        <div>
            <div className="d-flex flex-column justify-content-center gap-3   ">
                    <div className="d-flex flex-column w-100 gap-2  line">
                        <div className="d-flex flex-column justify-content-center gap-1 w-50 sm-screen-size">                         
                            <div><h5>Price ($)</h5></div>
                            <div className=" w-100 d-flex flex-column ">
                                <input className="textarea d-flex w-100 p-2  "type="text"placeholder=" How much do you want to sell your property?"/>
                            </div>                           
                        </div>
                        <div>
                            <label className="form-check-label d-flex gap-2 fs-6 mb-4 ">
                                <input type="checkbox"className="form-check-input " name="" id=""value="checkedValue"/>
                                Negotiable 
                            </label>
                        </div>
                        
                    </div>
                    <div className="line ">
                        <div className=" mb-2">
                            <div className="fs-5 fw-bold"> Add Your Property Photos</div>
                            <div className="fs-6 text-muted" >Upload best photos for buyers interested</div>
                        </div>
                        <div className='d-flex p-2 gap-3 mb-4 row row-cols-6 row-cols-md-4  row-cols-lg-6 cols-sm-4  '>
                            <div className=' d-flex justify-content-center  '><img className='img-fluid object-fit-cover border p-2' src={AddPhotos} alt="" /></div>
                            <div className=' d-flex  justify-content-center '><img className='img-fluid object-fit-cover border' src={AddPhotos} alt="" /></div>
                            <div className=' d-flex  justify-content-center '><img className='img-fluid object-fit-cover border' src={AddPhotos} alt="" /></div>
                            <div className=' d-flex  justify-content-center '><img className='img-fluid object-fit-cover border' src={AddPhotos} alt="" /></div>
                            <div className=' d-flex  justify-content-center '><img className='img-fluid object-fit-cover border' src={AddPhotos} alt="" /></div>
                            <div className=' d-flex justify-content-center '><img className='img-fluid object-fit-cover border' src={AddPhotos} alt="" /></div>
                        </div>

                    </div>
                    <div className="d-flex flex-column gap-3">
                        <div className="fs-5 fw-bold">Contact Details</div>
                        <div className="d-flex flex-column w-100 ">
                            <div className="text-muted"><h6>Name</h6></div>
                            <div><h6>Md Solaiman Ovi</h6></div>
                        </div>
                        <div className="d-flex flex-column w-100 ">
                            <div className="text-muted"><h6>Email</h6></div>
                            <div><h6>msovi480@gmail.com</h6></div>
                        </div>
                        <div className="d-flex flex-column justify-content-center  w-50 sm-screen-size">                         
                            <div className="text-muted"><h6>Add Phone Number</h6></div>
                            <div className=" w-100 d-flex flex-column ">
                                <input className="textarea d-flex w-100 p-2 "type="text"placeholder="How much do you want to sell your property?"/>
                            </div>                           
                        </div>
                        <div>
                            <label className="form-check-label d-flex gap-1 fs-6 mb-4 ">
                                <input type="checkbox"className="form-check-input " name="" id=""value="checkedValue"/>
                                I agree with the terms & conditions 
                            </label>
                        </div>


                    </div>
                    <div className='d-flex gap-3 mb-5'>
                        <div className="d-flex justify-content-start  gap-5">
                            <Link to="/login/add-property"> 
                                <button type="button" className="btn btn-outline-primary px-4 "><i className="fas fa-arrow-left " ></i>  Previous</button>
                            </Link>
                        </div>
                        <div className="d-flex justify-content-start  gap-5">
                                <button type="button" className="btn btn-outline-primary px-4 "> Add Post</button>
                        </div>

                    </div>
                    
                    
            </div>  
        </div>
  );
};
export default SellPost2;
